import { useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { DefaultLayout } from '../../../components/layouts';
import { KerijBox, KerijBoxType } from '../../../components/base';
import validator from 'validator';
import { useError } from '@/shared/useError';
import { StepProgressBar } from '../../../features/declaration/components/bars/StepProgressBar';
import { NavigationContext } from '../../../components/Context/NavigationContext';
import { NavigationSteps } from '../../../components/Context/NavigationProvider';
import { RouterConfig } from '../../../config/RouterConfig';
import { AccountContext, useAppSelector } from '@/store';
import { Button } from '@/components/Button';
import { Spacer } from '@/components/Spacer';
import { DeclarationContact } from './DeclarationContact';
import { FormLabel } from '@mui/material';
import { Absence, AbsenceContactType } from '@/types';
import { DeclarationContactType } from './DeclarationContactType';
import { DeclarationContactInput } from './DeclarationContactInput';
import { DeclarationContactPermission } from './DeclarationContactPermission';
import { ActionButton } from '@/components/ActionButton';

export interface DeclarationContactPermission {
  canContact: boolean;
  canContactByMail: boolean;
  canContactByPhone: boolean;
}

export interface DeclarationContactModel {
  phone?: string;
  email?: string;
}

const isEmailError = (email?: string) => {
  return email == null || email === '' || !validator.isEmail(email);
};

const isPhoneError = (phone?: string) => {
  return phone == null || phone === '' || !validator.isMobilePhone(phone);
};

export const DeclarationContactPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector(AccountContext.getUser);
  const { setCurrentStep } = useContext(NavigationContext);
  const { error, triggerError, clearError } = useError();

  const document = location.state.document || {};
  const documentContact = document.contact;
  const absenceType = document?.absenceType?.value;
  const sex = user?.employee_information?.civility;

  const [permissions, setPermissions] = useState<DeclarationContactPermission>({
    canContact: documentContact?.permissions?.canContact || false,
    canContactByMail: documentContact?.permissions?.canContactByMail || false,
    canContactByPhone: documentContact?.permissions?.canContactByPhone || true,
  });

  const [contactType, setContactType] = useState<Absence['contact_type']>(documentContact?.contactType || null);

  const [init, setInit] = useState(false);
  const [model, setModel] = useState<DeclarationContactModel>({});

  const verify = (value: DeclarationContactModel, type: Absence['contact_type']) => {
    if (!init) return;

    const isUserPhone = user?.phone && user.phone === value.phone;
    const isUserEmail = user?.email && user.email === value.email;

    if ((isUserPhone && isUserEmail) || type == null) return;
    else {
      if (isPhoneError(value.phone) && isEmailError(value.email))
        return 'Votre numéro de téléphone et votre adresse mail ne sont pas au bon format';
      if (!isUserPhone && isPhoneError(value.phone)) return "Votre numéro de téléphone n'est pas au bon format";
      if (!isUserEmail && isEmailError(value.email)) return "Votre adresse mail n'est pas au bon format";
    }
  };

  useEffect(() => {
    const error = verify(model, contactType);
    if (error) return triggerError(error);
    else clearError();
  }, [model, contactType]);

  const handleClickNext = () => {
    if (user) {
      navigate(RouterConfig.declarationQuestionsMessagePage().path, {
        state: {
          ...location.state,
          document: {
            ...document,
            contact: {
              contactType,
              permissions,
              phone: contactType && permissions.canContactByPhone ? model.phone : undefined,
              email: contactType && permissions.canContactByMail ? model.email : undefined,
            },
          },
        },
      });
    } else {
      navigate(RouterConfig.loginPage().path, { state: location.state });
    }
  };

  const handleClickPrevious = () => {
    navigate('/declaration-document', {
      state: location.state,
    });
  };

  useEffect(() => {
    setCurrentStep(NavigationSteps.DECLARATION_QUESTIONS_CONTACT);
  }, []);

  useEffect(() => {
    if (user == null) setInit(false);
    else if (user && !init) {
      console.log(documentContact);
      setModel({
        phone: documentContact?.phone ?? user.phone ?? null,
        email: documentContact?.email ?? user.email ?? null,
      });
      setInit(true);
    }
  }, [user, document]);

  return (
    <DefaultLayout>
      <div className="container-fluid">
        <StepProgressBar type={absenceType} />
        <h3 className="question-form mt-5 text-center">
          Acceptez vous d’être {sex === '1' ? 'contactée' : 'contacté'} par votre entreprise pendant votre absence ?
        </h3>

        <div style={{ maxWidth: '350px', width: '100%' }}>
          <Spacer size="large" />
          <DeclarationContact
            value={permissions.canContact}
            onChange={(value) => {
              setPermissions((data) => ({ ...data, canContact: value }));
              setContactType(value ? AbsenceContactType.Manager : null);
            }}
          />
          <Spacer size="large" />

          {init && permissions.canContact && (
            <div>
              <DeclarationContactType value={contactType} onChange={setContactType} />
              <Spacer size="large" />

              <FormLabel>Par quel moyen de communication ?</FormLabel>

              <div>
                <DeclarationContactPermission
                  value={permissions.canContactByPhone}
                  onChange={() => {
                    setPermissions((data) => ({
                      ...data,
                      canContactByPhone: data.canContactByMail && data.canContactByPhone ? false : true,
                    }));
                  }}
                  label="Par téléphone"
                />

                {permissions.canContactByPhone === true && (
                  <DeclarationContactInput
                    type="phone"
                    value={model.phone}
                    onChange={(value) => setModel((data) => ({ ...data, phone: value ?? '' }))}
                  />
                )}
              </div>

              <Spacer size="small" />

              <div>
                <DeclarationContactPermission
                  value={permissions.canContactByMail}
                  onChange={() => {
                    setPermissions((data) => ({
                      ...data,
                      canContactByMail: data.canContactByPhone && data.canContactByMail ? false : true,
                    }));
                  }}
                  label="Par email"
                />
                {permissions.canContactByMail === true && (
                  <DeclarationContactInput
                    type="email"
                    value={model.email}
                    onChange={(value) => setModel((data) => ({ ...data, email: value ?? '' }))}
                  />
                )}
              </div>
            </div>
          )}

          <Spacer size="large" />

          {error ? <KerijBox type={KerijBoxType.Error} message={error} /> : null}

          <Spacer size="medium" />
          <ActionButton
            startIcon={null}
            type="submit"
            variant="contained"
            actionName="next"
            disabled={error != null}
            onClick={handleClickNext}
            style={{ width: '100%' }}
          />
          <Spacer />
          <Button color="secondary" onClick={handleClickPrevious} style={{ width: '100%' }}>
            Précédent
          </Button>
        </div>
      </div>
    </DefaultLayout>
  );
};
