import React, { useEffect, useState } from 'react';
import classNames from 'clsx';
import { Box, BoxProps } from '@mui/material';
import { Switch } from '@/components/Switch';
import { Parcours } from '@/types';
import { ParcoursQuery } from '@/query';
import { useToast } from '../Toast';
import { isAxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';

const componentName = 'ParcoursSwitchActive';

export interface ParcoursSwitchActiveProps extends Omit<BoxProps, 'onChange'> {
  value: Pick<Parcours, 'active'> & { id?: Parcours['id'] };
  disabled?: boolean;
  onChange?: (value: Parcours['active']) => void;
}

export const ParcoursSwitchActive = (props: ParcoursSwitchActiveProps) => {
  const toast = useToast();
  const { value, disabled, className, onChange, ...boxProps } = props;

  const parcoursUpdateMutation = ParcoursQuery.useUpdate();
  const [isActive, setIsActive] = useState(value.active);
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function onToggle(id: Parcours['id'] | undefined, active: boolean) {
    setIsSubmitting(true);
    try {
      if (id == null) {
        toast.present({ message: 'Le parcours ne semble pas exister', severity: 'error' });
      } else {
        await parcoursUpdateMutation.mutateAsync({ id, active });
        setIsActive(active);
        onChange?.(active);
      }
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === StatusCodes.FORBIDDEN)
        toast.present({ message: "Vous n'avez pas les droits pour modifier un parcours", severity: 'error' });
      else toast.present({ message: 'Une erreur est survenue lors de la modification', severity: 'error' });
    }
    setIsSubmitting(false);
  }

  useEffect(() => setIsActive(value.active), [value.active]);

  return (
    <Box className={classNames(componentName, className)} {...boxProps}>
      {value.id != null && (
        <Switch
          readOnly={isSubmitting || disabled}
          checked={isActive}
          onChange={(_, checked) => onToggle(value.id, checked)}
        />
      )}
    </Box>
  );
};
