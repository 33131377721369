import React from 'react';
import {
  Paper,
  MenuList,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Divider,
  Typography,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import { AbsenceSearchFilter, AbsenceSearchFilterTypeItem } from './data';
import { emptyAbsenceSearchFilter } from './CurrentAbsenceSearchBar';
import { ActionButton } from '@/components/ActionButton';

interface CurrentAbsenceSearchBarFiltersProps {
  open?: boolean;
  value: AbsenceSearchFilter;
  disablePortfolio?: boolean;
  onChange?: (value: AbsenceSearchFilter) => void;
  onSubmit?: (value: AbsenceSearchFilter) => void;
  onCancel?: () => void;
}

export const CurrentAbsenceSearchBarFilters = (parameters: CurrentAbsenceSearchBarFiltersProps): JSX.Element => {
  const { open, value, disablePortfolio, onCancel, onChange, onSubmit } = parameters;

  function changeFilterType(key: string, filter: AbsenceSearchFilterTypeItem) {
    onChange?.({
      ...value,
      type: {
        ...value.type,
        [key]: { ...filter, checked: !filter.checked },
      },
    });
  }

  return (
    <>
      {open && (
        <Paper sx={{ width: 370, position: 'absolute', zIndex: '100000', ml: 1.5 }}>
          <MenuList id="target-id">
            <MenuItem>
              <FormControlLabel
                label={'Mon portefeuille uniquement'}
                color="primary"
                sx={{ margin: 0 }}
                control={
                  <Checkbox
                    color="primary"
                    checked={value.portfolio}
                    disabled={disablePortfolio}
                    onChange={(_, isCheck) => {
                      onChange?.({ ...value, portfolio: isCheck });
                    }}
                  />
                }
              />
            </MenuItem>

            <Divider />
            <MenuItem disabled>
              <Typography variant="caption">{`Type d'absences`}</Typography>
            </MenuItem>

            {Object.entries(value.type).map(([key, filter]) => (
              <>
                <MenuItem onClick={() => changeFilterType(key, filter)} selected={filter.checked}>
                  <ListItemIcon>
                    <Typography>{filter.icon}</Typography>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography>{filter.label}</Typography>
                  </ListItemText>
                  <Typography>
                    <Checkbox checked={filter.checked} />
                  </Typography>
                </MenuItem>
              </>
            ))}

            <Divider />
            <Stack direction="row" spacing={1} style={{ padding: '0.25rem 0.75rem', justifyContent: 'end' }}>
              <ActionButton
                actionName="cancel"
                label={'Retirer'}
                onClick={() => {
                  onChange?.(emptyAbsenceSearchFilter);
                  onCancel?.();
                }}
              />
              <ActionButton
                actionName="validate"
                label={'Appliquer'}
                onClick={() => {
                  onSubmit?.(value);
                }}
              />
            </Stack>
          </MenuList>
        </Paper>
      )}
    </>
  );
};
