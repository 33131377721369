import React from 'react';
import { Paper, MenuList, MenuItem, FormControlLabel, Checkbox, Divider, Stack } from '@mui/material';
import { Button } from '@/components/Button';
import { emptyNotificationSearchFilter } from './CollaborateurSearchBar';
import { CollaborateurSearchFilter } from './data';
import { ActionButton } from '@/components/ActionButton';

interface CollaborateurSearchBarFilterProperties {
  open?: boolean;
  value: CollaborateurSearchFilter;
  disablePortfolio?: boolean;
  onChange?: (value: CollaborateurSearchFilter) => void;
  onSubmit?: (value: CollaborateurSearchFilter) => void;
  onCancel?: () => void;
}

export const CollaborateurSearchBarFilter = (parameters: CollaborateurSearchBarFilterProperties): JSX.Element => {
  const { open, value, disablePortfolio, onCancel, onChange, onSubmit } = parameters;

  return (
    <>
      {open && (
        <Paper sx={{ width: 370, position: 'absolute', zIndex: '100000', ml: 1.5 }}>
          <MenuList id="target-id">
            <MenuItem>
              <FormControlLabel
                label={'Mon portefeuille uniquement'}
                color="primary"
                sx={{ margin: 0 }}
                control={
                  <Checkbox
                    color="primary"
                    checked={value.portfolio}
                    disabled={disablePortfolio}
                    onChange={(_, isCheck) => {
                      onChange?.({ ...value, portfolio: isCheck });
                    }}
                  />
                }
              />
            </MenuItem>

            <Divider />
            <Stack direction="row" spacing={1} style={{ padding: '0.25rem 0.75rem', justifyContent: 'end' }}>
              <ActionButton
                actionName="cancel"
                label={'Retirer'}
                onClick={() => {
                  onChange?.(emptyNotificationSearchFilter);
                  onCancel?.();
                }}
              />
              <ActionButton
                actionName="validate"
                label={'Appliquer'}
                onClick={() => {
                  onSubmit?.(value);
                }}
              />
            </Stack>
          </MenuList>
        </Paper>
      )}
    </>
  );
};
