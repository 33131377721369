import React, { createRef, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import moment from 'moment';
import './Historique.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pdfjs } from 'react-pdf';
import isAuthorized from '../../hooks/isAuthorized';
import { useError } from '@/shared/useError';
import KerijButton from '../base/button/kerijButton';
import {
  employeeFetchAbsences,
  getInterviewDocumentEmployee,
  medicalVisit,
  viewDocumentEmployee,
} from '../../services/employee';
import { StatusCodes } from 'http-status-codes';
import { verifyToken } from '../../services/auth';
import { displayDateEndOrHospi, toFormatDate } from '../../utils/date.utils';
import { useAppSelector } from '@/store';
import { useToast } from '../Toast';
import { ActionButton } from '../ActionButton';
import { Box } from '@mui/material';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function HistoriqueSalarie() {
  const location = useLocation();
  const toast = useToast();

  const user = useAppSelector((state) => state.accountContext.user);
  const loading = useAppSelector((state) => state.accountContext.loading);
  const [listAbsence, setListAbsence] = useState([]);
  const [selectAbsence, setSelectAbsence] = useState();
  const [canModify, setCanModify] = useState(-1);
  const [loadingAbsence, setLoadingAbsence] = useState(false);
  const [files, setFiles] = useState([]);
  const [_, setError] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [src, setSrc] = useState('');
  const state = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token_decode') || null;
  const navigate = useNavigate();
  const [typeFile, setTypeFile] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [ficheEntretien, setFicheEntretien] = useState([]);
  const [ficheMedical, setFicheMedical] = useState([]);
  const [scale, setScale] = useState(1);
  const [offset, setOffset] = useState(10);
  const { error, triggerError, clearError } = useError();

  const getFicheEntretiens = async () => {
    if (user) {
      await getInterviewDocumentEmployee().then((value) => {
        setFicheEntretien(value.data.documents);
      });
    }
  };

  const getMedicalFile = async () => {
    if (user) {
      await medicalVisit().then((value) => {
        setFicheMedical(value.data.documents);
      });
    }
  };

  const handleScroll = (listAbsence, location) => {
    if (
      location.pathname === '/historiquesalarie' &&
      // eslint-disable-next-line unicorn/prefer-query-selector
      document.getElementById('employee-absence') &&
      // eslint-disable-next-line unicorn/prefer-query-selector
      document.getElementById('employee-absence').getBoundingClientRect().bottom <= window.innerHeight
    ) {
      setOffset(listAbsence.length + 10);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll.bind(this, listAbsence, location));
    return () => window.removeEventListener('scroll', handleScroll.bind(this, listAbsence, location));
  }, [listAbsence, location]);

  const handleViewDocument = async (document) => {
    window.open(document.url, '_blank');
  };

  const handleUpdateAbsence = async (absence) => {
    const typeAbsence = absence.type_absence;
    navigate(`/declaration-document`, {
      state: {
        document: {
          absenceType: {
            value: typeAbsence.description + '',
            label: typeAbsence.description_label,
          },
          absenceType1: {
            value: typeAbsence.description_1 + '',
            label: typeAbsence.description_1_label,
          },
          absenceType2: {
            value: typeAbsence.description_2 + '',
            label: typeAbsence.description_2_label,
          },
          dateDebut: moment(absence.date_start).format('YYYY-MM-DD'),
          dateFin: moment(absence.date_end).format('YYYY-MM-DD'),
        },
        isUpdate: true,
        absenceToUpdate: {
          id: absence.id,
          dateAction: absence.date_action,
          absences: absence.absences,
        },
      },
    });
  };

  const handleFetchAbsences = async (idAbsence) => {
    try {
      const response = await employeeFetchAbsences(idAbsence, offset);
      setListAbsence((oldList) =>
        response.data.absences.rows.map((absence) => {
          absence.show = false;
          absence.ref = createRef();
          return absence;
        }),
      );
      if (idAbsence) {
        setCanModify(idAbsence);
        const selectedAbsence = response.data.absences.find((absence) => absence.id === idAbsence);
        if (selectedAbsence) {
          selectedAbsence.show = false;
          setSelectAbsence(selectedAbsence);
        }
      }
    } catch {
      triggerError('Une erreur est survenue lors de la récupération des absences');
    }
  };
  const handleVerifyToken = () => {
    try {
      return verifyToken({ token });
    } catch {
      triggerError();
    }
  };

  useEffect(() => {
    clearError();
    if (!isAuthorized(window.location.pathname)) {
      if (state.state) {
        if (token) {
          navigate(`/login?token_decode=${token}`, {
            state: {
              document: state.state.document,
              previousPage: 'historiqueSalarie',
            },
          });
        } else {
          navigate(`/login`, {
            state: { document: state.state.document },
          });
        }
      } else {
        if (token) {
          navigate(`/login?token_decode=${token}`, {
            state: { previousPage: 'historiqueSalarie' },
          });
        } else {
          navigate(`/login`);
        }
      }
      return;
    }
    if (token) {
      handleVerifyToken().then((response) => {
        const idAbsence = response.data.decode.idAbsence;
        handleFetchAbsences(idAbsence);
      });
    } else {
      handleFetchAbsences(null);
    }
  }, [loading, offset]);

  useEffect(() => {
    if (files.some((value) => value.status === 0)) {
      triggerError('Il y a des fichiers invalidé');
    } else {
      clearError();
    }
  }, [files]);

  useEffect(() => {
    getFicheEntretiens();
    getMedicalFile();
  }, [user]);

  useEffect(() => {
    if (token && listAbsence.length > 0) {
      verifyToken({ token })
        .then((val) => {
          if (val.status === StatusCodes.OK) {
            const { idAbsence } = val.data.decode;
            if (listAbsence.filter((val) => val.id === idAbsence).at(0)) {
              listAbsence
                .filter((val) => val.id === idAbsence)
                .at(0)
                .ref?.current?.scrollIntoView({
                  behavior: 'smooth',
                });
            }
            searchParams.delete('token');
            setSearchParams(searchParams);
          }
        })
        .catch(() => {
          toast.present({ message: 'Connexion interrompue', severity: 'error' });
        });
    }
  }, [listAbsence]);

  return (
    <div>
      {(loading && !user) || loadingAbsence ? (
        <div className="container-fluid container-loading">
          <h2>Veuillez patienter</h2>
          <div className="container-load">
            <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
          </div>
        </div>
      ) : (
        <div>
          {user && (
            <div className="container-fluid mt-5 text-center">
              <h1 className="question-form mb-5 text-center">Activités</h1>

              <div className="row col-12 col-lg-10 text-center">
                <div className="row col-12 mt-3 mb-3">
                  <Link className="dashboard-choix-non-actif col-6" to={'/activites'}>
                    <span>Messages</span>
                  </Link>

                  <Link className="dashboard-choix-actif col-6" to={'/historiquesalarie'}>
                    <span>Actions</span>
                  </Link>
                </div>

                {listAbsence.length === 0 && (
                  <div id="employee-absence" className="col-12">
                    <h5 className="mb-3 mt-5 text-start">
                      <strong>Évènements d'absence</strong>
                    </h5>
                    <div className="col-xl-12 col-12 text-center mt-5">Aucun évènement déclaré pour l'instant</div>
                  </div>
                )}
                {listAbsence.length > 0 && (
                  <div id="employee-absence" className="col-12">
                    <h5 className="mb-3 mt-5 text-start">
                      <strong>Évènements d'absence</strong>
                    </h5>
                    {listAbsence.map((absence, index) => {
                      return (
                        <div key={absence.id} ref={absence.ref}>
                          <div className="row-histo py-3 mb-3 bg-body rounded-3 row text-center">
                            <div className="col-12 col-lg-3">
                              <div
                                style={{
                                  marginRight: 'auto',
                                  textAlign: 'center',
                                }}
                              >
                                Du <strong>{toFormatDate(absence.date_start)}</strong> au{' '}
                                <strong>{displayDateEndOrHospi(absence)}</strong>
                                <br />
                              </div>
                            </div>
                            <Box className="col-12 col-lg-9" textAlign={'right'}>
                              {absence.documents?.length > 0 &&
                                absence.documents.map((document, index) => {
                                  return (
                                    <ActionButton
                                      key={index}
                                      actionName="show"
                                      onClick={() => handleViewDocument(document)}
                                    />
                                  );
                                })}
                              {(absence.documents?.length === 0 || absence.date_end === null) && (
                                <ActionButton
                                  label={'Mettre à jour'}
                                  variant="contained"
                                  actionName="next"
                                  disabled={error != null}
                                  onClick={() => handleUpdateAbsence(absence)}
                                  sx={{ ml: 1 }}
                                />
                              )}
                            </Box>
                            {absence.absences?.length > 0 && [3, 4].includes(absence.type_absence.description) && (
                              <div className="col-12 col-lg-1">
                                <FontAwesomeIcon
                                  className="fa-1px"
                                  icon={!absence.show ? 'fa-angle-right' : 'fa-angle-down'}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setListAbsence((listAbsence) =>
                                      listAbsence.map((v, i) => {
                                        if (v.id === absence.id) {
                                          v.show = !v.show;
                                        }
                                        return v;
                                      }),
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          {absence.show &&
                            absence.absences.map((v, i) => {
                              return (
                                <div
                                  className="row-histo py-3 mb-3 bg-body rounded-3 row text-center"
                                  style={{
                                    marginLeft: '5%',
                                  }}
                                  key={i}
                                >
                                  <div className="col-12 col-lg-3 mb-2">
                                    <div
                                      style={{
                                        marginRight: 'auto',
                                        textAlign: 'left',
                                      }}
                                    >
                                      Du <strong>{moment(v.date_start).format('DD/MM/YYYY')}</strong> au{' '}
                                      <strong>{moment(v.date_end).format('DD/MM/YYYY')}</strong>
                                      <br />
                                    </div>
                                    {v.birthday && (
                                      <div
                                        style={{
                                          marginRight: 'auto',
                                          textAlign: 'left',
                                        }}
                                      >
                                        <span>
                                          Date de Naissance : <strong>{moment(v.birthday).format('DD/MM/YYYY')}</strong>
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-12 col-lg-5 mb-2">
                                    {v.type_absence.description_label +
                                      (v.type_absence.description_1_label &&
                                      v.type_absence.description_1_label !== 'Hospitalisation'
                                        ? ` - ${v.type_absence.description_1_label}`
                                        : ' ') +
                                      (v.type_absence.description_2_label
                                        ? ` - ${v.type_absence.description_2_label}`
                                        : ' ')}
                                  </div>
                                  <div className="col-12 col-lg-2 mb-2">
                                    {v.documents.length === 0 && <div>Dossier incomplet</div>}
                                    {v.documents.length > 0 &&
                                      v.documents.map((v, i) => {
                                        return (
                                          <div
                                            key={i}
                                            style={{
                                              cursor: 'pointer',
                                            }}
                                            onClick={async () => {
                                              await viewDocumentEmployee(v, user, 'document').then((val) => {
                                                var file = new Blob([val.data], {
                                                  type:
                                                    v.name.split('.')[1].toLowerCase() === 'pdf'
                                                      ? 'application/pdf'
                                                      : 'image/png',
                                                });
                                                if (v.name.split('.')[1].toLowerCase() === 'pdf') {
                                                  setTypeFile('pdf');
                                                  setSrc(URL.createObjectURL(file));
                                                  setModalIsOpen(true);
                                                } else {
                                                  setTypeFile('img');
                                                  setSrc(URL.createObjectURL(file));
                                                  setModalIsOpen(true);
                                                }
                                              });
                                            }}
                                          >
                                            {v.name}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                  </div>
                )}
                {ficheEntretien.length === 0 && (
                  <div id="employee-absence" className="col-12">
                    <h5 className="mb-3 mt-5 text-start">
                      <strong>Mes entretiens</strong>
                    </h5>
                    <div className="col-xl-12 col-12 text-center mt-5">Pas d'entretien effectué</div>
                  </div>
                )}
                {ficheEntretien.length > 0 && (
                  <div id="employee-entretien" className="col-12">
                    <h5 className="mb-3 mt-5 text-start">
                      <strong>Mes entretiens</strong>
                    </h5>
                    {ficheEntretien.map((value, index) => {
                      return (
                        <div className="card col-12 mt-2" key={index}>
                          <div className="card-body card-style">
                            <div className="row col-12">
                              <p className="card-text text-center col-12 col-xl-4 contacts-libelle">
                                <strong>{moment(value.date_interview).format('DD/MM/YYYY')}</strong>
                              </p>
                              <p className="card-text text-center col-12 col-xl-4 contacts-libelle">{value.type}</p>
                              {value.url && (
                                <div
                                  className="col-xl-2 col-6 text-center contacts-modif"
                                  onClick={async () => {
                                    await viewDocumentEmployee(value, user, 'interview_document').then((val) => {
                                      var file = new Blob([val.data], {
                                        type:
                                          value.name.split('.')[1].toLowerCase() === 'pdf'
                                            ? 'application/pdf'
                                            : 'image/png',
                                      });
                                      if (value.name.split('.')[1].toLowerCase() === 'pdf') {
                                        setTypeFile('pdf');
                                        setSrc(URL.createObjectURL(file));
                                        setModalIsOpen(true);
                                        setScale(1);
                                      }
                                    });
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                >
                                  Visualiser
                                </div>
                              )}
                              {value.url && (
                                <div
                                  className="col-xl-1 col-6"
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={async () => {
                                    await viewDocumentEmployee(value, user, 'interview_document').then((val) => {
                                      var file = new Blob([val.data], {
                                        type:
                                          value.name.split('.')[1].toLowerCase() === 'pdf'
                                            ? 'application/pdf'
                                            : 'image/png',
                                      });
                                      var fileURL = URL.createObjectURL(file);
                                      var a = document.createElement('a');
                                      document.body.appendChild(a);
                                      a.style = 'display: none';
                                      a.href = fileURL;
                                      a.download = `${value.name}`;
                                      a.click();
                                      a.remove();
                                      window.URL.revokeObjectURL(fileURL);
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-download" />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {ficheMedical.length === 0 && (
                  <div id="employee-visite" className="col-12">
                    <h5 className="mb-3 mt-5 text-start">
                      <strong>Mes visites médicales de pré-reprise et de reprise</strong>
                    </h5>
                    <div className="col-xl-12 col-12 text-center mt-5">Pas de visites médicales</div>
                  </div>
                )}
                {ficheMedical.length > 0 && (
                  <div id="employee-entretien" className="col-12">
                    <h5 className="mb-3 mt-5 text-start">
                      <strong>Mes visites médicales de pré-reprise et de reprise</strong>
                    </h5>
                    {ficheMedical.map((value, index) => {
                      return (
                        <div className="card col-12 mt-2" key={index}>
                          <div className="card-body card-style">
                            <div className="row col-12">
                              <p className="card-text text-center col-12 col-xl-4 contacts-libelle">
                                <strong>{moment(value.date_visit).format('DD/MM/YYYY')}</strong>
                              </p>
                              <p className="card-text text-center col-12 col-xl-4 contacts-libelle">{value.type}</p>
                              {value.url && (
                                <div
                                  className="col-xl-2 col-6 text-center contacts-modif"
                                  onClick={async () => {
                                    await viewDocumentEmployee(value, user, 'medical_document').then((val) => {
                                      var file = new Blob([val.data], {
                                        type:
                                          value.name.split('.')[1].toLowerCase() === 'pdf'
                                            ? 'application/pdf'
                                            : 'image/png',
                                      });
                                      if (value.name.split('.')[1].toLowerCase() === 'pdf') {
                                        setTypeFile('pdf');
                                        setSrc(URL.createObjectURL(file));
                                        setModalIsOpen(true);
                                        setScale(1);
                                      }
                                    });
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                >
                                  Visualiser
                                </div>
                              )}
                              {value.url && (
                                <div
                                  className="col-xl-1 col-6"
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={async () => {
                                    await viewDocumentEmployee(value, user, 'medical_document').then((val) => {
                                      var file = new Blob([val.data], {
                                        type:
                                          value.name.split('.')[1].toLowerCase() === 'pdf'
                                            ? 'application/pdf'
                                            : 'image/png',
                                      });
                                      var fileURL = URL.createObjectURL(file);
                                      var a = document.createElement('a');
                                      document.body.appendChild(a);
                                      a.style = 'display: none';
                                      a.href = fileURL;
                                      a.download = `${value.name}`;
                                      a.click();
                                      a.remove();
                                      window.URL.revokeObjectURL(fileURL);
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-download" />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default HistoriqueSalarie;
