import React from 'react';
import { StepContent, StepLabel } from '@mui/material';
import { AbsencesFormSubmit } from './AbsencesFormSubmit';

interface AbsencesFormItemProps {
  size: number;
  step: {
    index: number;
    label: string;
    option?: JSX.Element | null;
    description: JSX.Element;
  };
  isSubmit: boolean;
  disabledConfirm?: boolean;
  handleNext: () => void;
  handleBack: () => void;
}

export const AbsencesFormItem = ({
  step,
  size,
  isSubmit,
  disabledConfirm,
  handleNext,
  handleBack,
}: AbsencesFormItemProps) => {
  return (
    <>
      <StepLabel optional={step.option}>{step.label}</StepLabel>

      <StepContent>
        {step.description}

        {!isSubmit && (
          <AbsencesFormSubmit
            actionName={step.index === size - 1 ? 'delete' : 'next'}
            disabledConfirm={disabledConfirm}
            disabledCancel={step.index === 0}
            onConfirm={handleNext}
            onCancel={handleBack}
          />
        )}
      </StepContent>
    </>
  );
};
