import { z } from 'zod';
import { ParcoursAction, ParcoursActionName } from './ParcoursAction';
import { ParcoursRule } from './ParcoursRule';
import { ParcoursScriptFrequency } from './ParcoursScriptFrequency';

export const ParcoursScriptReferenceDate = z.enum(['parcoursTriggeredAt', 'absenceLastDateEnd'] as const, {
  description: 'Reference date for script action trigger',
});
export type ParcoursScriptReferenceDate = z.infer<typeof ParcoursScriptReferenceDate>;

export const ParcoursScriptPresence = z.enum(['any', 'present', 'absent'] as const, {
  description: 'Employee presence filter for script action trigger',
});
export type ParcoursScriptPresence = z.infer<typeof ParcoursScriptPresence>;

export const ParcoursScriptWhen = z.object({
  reference_date: ParcoursScriptReferenceDate,
  delay: z.number({ description: 'Delay from date in milliseconds' }).optional(),
  employee_presence: ParcoursScriptPresence.optional(),
});
export interface ParcoursScriptWhen extends z.infer<typeof ParcoursScriptWhen> {}

export interface ParcoursScript {
  $schema?: string | null;
  version: number;
  rule: {
    where: Array<ParcoursRule>;
  };
  action?: Record<ParcoursActionName, ParcoursAction>;
  when?: ParcoursScriptWhen;
  frequency?: ParcoursScriptFrequency;
}
