import React, { useEffect, useState } from 'react';
import { DefaultLayout } from '@/components/layouts';
import { Link, useNavigate } from 'react-router-dom';
import ImgSoutien from '@/assets/img-btnsoutien.svg';
import ImgHeader from '@/assets/header-declaration-iconkerij.svg';
import ImgServices from '@/assets/img-declaration-services.svg';
import ImgArret from '@/assets/arret_travail.jpg';
import ImgHospi from '@/assets/hospitalisation.jpg';
import ImgAccident from '@/assets/accident_du_travail.jpg';
import { RouterConfig } from '@/config/RouterConfig';
import { absenceType as absenceTypeConstant } from '@/features/dashboard-rh/create-absence-rh/constants/absenceType.constant';
import { colors } from '@/constants';
import { AccountContext, useAppSelector } from '@/store';
import { IfFeature } from '@/components/IfFeature';
import { ServiceTheme } from '@/types';
import { DeclarationDesktopWarning } from './DeclarationDesktopWarning';
import { ActionButton } from '@/components/ActionButton';

const getStateTransfer = (absenceType: number, absenceTypeLabel: string) => {
  switch (absenceType) {
    case absenceTypeConstant.arret_maladie: {
      return {
        document: {
          absenceType: { value: '1', label: 'Arrêt de travail' },
          absenceType1: { value: '1', label: 'Maladie' },
        },
      };
    }
    case absenceTypeConstant.accident_arret_professionnel: {
      return {
        document: {
          absenceType: { value: '1', label: 'Arrêt de travail' },
          absenceType1: {
            value: '2',
            label: 'Accident - Maladie professionnelle',
          },
        },
      };
    }
    default: {
      return {
        document: {
          absenceType: {
            value: absenceType.toString(),
            label: absenceTypeLabel,
          },
        },
      };
    }
  }
};

interface DeclarationIconInterface {
  title1: string;
  title2: string;
  onClick: () => void;
  image: any;
}

const DeclarationIcon = ({ title1, title2, onClick, image }: DeclarationIconInterface) => {
  return (
    <button onClick={onClick} type="button" className="btn btn-lg btn-declaration gradiant-hover-declaration">
      <div className="col-12 row">
        <div className="col-8 pe-0 col-sm-10">
          <div className="btn-declaration-sub">{title1}</div>

          <div className="btn-declaration-title">{title2}</div>
        </div>{' '}
        <div className="col-4 col-sm-2 ">
          <img width={80} alt="kerij" className="btn-declaration-img" src={image} />
        </div>
      </div>
    </button>
  );
};
export const DeclarationPage = () => {
  const user = useAppSelector(AccountContext.getUser);

  const navigate = useNavigate();

  const isMobile = () => /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent);
  const [openModal, setOpenModal] = useState(false);
  const [arretTypeDeclaration, setArretTypeDeclaration] = useState<'MA' | 'HO' | 'AT'>();

  const startDeclaration = (type?: 'MA' | 'HO' | 'AT') => {
    const declarationType = type ?? arretTypeDeclaration;

    if (declarationType === 'MA') {
      return navigate(RouterConfig.declarationType1Page().path, {
        state: getStateTransfer(absenceTypeConstant.arret_maladie, 'Maladie'),
      });
    }
    if (declarationType === 'HO') {
      return navigate(RouterConfig.declarationType1Page().path, {
        state: getStateTransfer(absenceTypeConstant.hospitalisation, 'Hospitalisation'),
      });
    }
    if (declarationType === 'AT') {
      return navigate(RouterConfig.declarationType1Page().path, {
        state: getStateTransfer(absenceTypeConstant.accident_arret_professionnel, 'Accident/Maladie professionnelle'),
      });
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <DefaultLayout>
      <div className=" header-declaration">
        <div className="row col-12 ps-0 mb-5">
          <h1
            className="col-12 col-lg-9"
            style={{
              color: colors.white,
              fontWeight: 'bold',
              marginTop: 30,
            }}
          >
            KERij vous assiste tout au long de votre absence
          </h1>
          <img alt="kerij" className="img-header-declaration" src={ImgHeader} />
        </div>

        <DeclarationDesktopWarning open={openModal} onClose={() => setOpenModal(false)} onConfirm={startDeclaration} />

        <div className="row">
          <div className="col-12">
            <DeclarationIcon
              title1={'Déclarer un'}
              title2={'Arrêt maladie'}
              onClick={() => {
                if (user == null) navigate('/login');
                else if (isMobile()) startDeclaration('MA');
                else {
                  setArretTypeDeclaration('MA');
                  setOpenModal(true);
                }
              }}
              image={ImgArret}
            />

            <DeclarationIcon
              title1={'Déclarer une'}
              title2={'Hospitalisation'}
              onClick={() => {
                if (user == null) navigate('/login');
                else if (isMobile()) startDeclaration('HO');
                else {
                  setArretTypeDeclaration('HO');
                  setOpenModal(true);
                }
              }}
              image={ImgHospi}
            />
            <DeclarationIcon
              title1={'Déclarer un'}
              title2={'Accident de travail'}
              onClick={() => {
                if (user == null) navigate('/login');
                else if (isMobile()) startDeclaration('AT');
                else {
                  setArretTypeDeclaration('AT');
                  setOpenModal(true);
                }
              }}
              image={ImgAccident}
            />
          </div>
        </div>
      </div>
      <IfFeature name="ServiceManagement">
        <div className="container-fluid soutien-declaration">
          <div className="row col-12 col-lg-9 mb-3 text-center">
            <div className="col-lg-6 ">
              <h2 className="declaration-h2">Demander un soutien psychologique</h2>
              <p className="declaration-subtitle-2">
                Connectez vous à votre assistant KERij et accédez à une ligne téléphonique et des professionnels à votre
                écoute.
              </p>
            </div>
            <div className="col-lg-6">
              <Link className="" to={'/services?theme=' + ServiceTheme.TPS}>
                <button type="button" className="btn btn-lg btn-declaration gradiant-hover-soutien">
                  <div className="col-12 row">
                    <div className="col-9 pe-0">
                      <div className="btn-declaration-sub">Demander un</div>
                      <div className="btn-declaration-title">Soutien Psychologique</div>
                    </div>
                    <div className="col-3 div-btn-declaration-img">
                      <i>
                        <img alt="kerij" className="btn-declaration-img" src={ImgSoutien} />
                      </i>
                    </div>
                  </div>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="container-fluid services-declaration">
          <h2 className="declaration-h2 mb-5">Nous vous facilitons l’accès aux services de votre employeur.</h2>
          <img alt="kerij" className="mb-5" src={ImgServices} />
          <div className="position-relative col-12 col-md-8 text-center mt-5 mb-3">
            {user ? (
              <Link className="btn-link" to="/services">
                <button type="button" className="btn btn-kerij-bleu col-6 col-lg-3">
                  Accéder à mes services
                </button>
              </Link>
            ) : (
              <Link className="btn-link" to="/login">
                {' '}
                <ActionButton color="primary" size="large" label={'Se connecter'} sx={{ pl: 10, pr: 10 }} />
              </Link>
            )}
          </div>
        </div>
      </IfFeature>
    </DefaultLayout>
  );
};
