import React, { useState } from 'react';
import { ContentStructure } from './common';
import { RHNotification } from '../../../../../../types/rh.interface';
import { CloseAbsenceModal } from '../ActionCardModal/CloseAbsenceModal';
import { ActionButton } from '@/components/ActionButton';

export interface BackToWorkActionsProps {
  rhNotification: RHNotification;
  onActionDone: () => void;
}

export const BackToWorkContent = () => {
  return (
    <ContentStructure
      title={"Gérer la fin d'une absence"}
      description={`Malgré nos relances, le collaborateur n’a pas mis à
                            jour le statut de son absence Sans cette
                            information, son dossier administratif ne peut être
                            mis à jour, avec des impacts en paie. Que
                            souhaitez-vous faire ?`}
    />
  );
};

export const BackToWorkActions = ({ rhNotification, onActionDone }: BackToWorkActionsProps) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div>
      <ActionButton
        actionName="next"
        color="secondary"
        label={"Clôturer l'absence"}
        onClick={() => setIsVisible(true)}
      />
      {rhNotification.employee_information && rhNotification.absence && (
        <CloseAbsenceModal
          absence={rhNotification.absence}
          employee_information={rhNotification.employee_information}
          visible={isVisible}
          onClose={() => setIsVisible(false)}
          onChange={onActionDone}
        />
      )}
    </div>
  );
};
