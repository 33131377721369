import React, { useEffect, useState } from 'react';
import './Profil.css';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../../../../Logotype-Icone-Fondclair.svg';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import { fetchEmployeeProfile } from '../../../../../services/employee';
import { removeAuthData } from '../../../../../utils/auth.utils';
import { UserTypes } from '../../../../../types/userTypes.enum';
import { customStyles } from '../../../../../shared/models/custom_modal.models';
import isAuthorized from '../../../../../hooks/isAuthorized';
import { AccountContext, useAppDispatch, useAppSelector } from '@/store';
import { EmployeeCivility } from '@/types';
import { ActionButton } from '@/components/ActionButton';

function ProfilUI() {
  const context = useAppSelector((state) => state.accountContext);
  const location = useLocation();
  const pathname = location.pathname;
  const state = useLocation();
  const dispatch = useAppDispatch();

  const [modalCGU, setModalCGU] = useState(false);
  const navigate = useNavigate();

  const getUserProfile = () => {
    fetchEmployeeProfile()
      .then((value) => {
        if (value.data.last_change_password) {
          dispatch(AccountContext.actions.setUser(value.data));
        } else {
          removeAuthData(UserTypes.EMPLOYEE);
          navigate('/login');
        }
      })
      .catch(async (err) => {
        removeAuthData(UserTypes.EMPLOYEE);
      });
  };

  const handleLogout = () => {
    removeAuthData(UserTypes.EMPLOYEE);
    dispatch(AccountContext.actions.setUser(null));

    navigate('/');
  };
  // const handleChangeContact = () => {
  //     navigate("/modifycontact", {state: {data: context.user, page: "Login"}})
  // }

  const handleChangeContactMail = () => {
    navigate('/modifycontactmail', {
      state: { data: context.user, page: 'Login' },
    });
  };

  const handleChangeContactTel = () => {
    navigate('/modifycontacttel', {
      state: { data: context.user, page: 'Login' },
    });
  };

  const handleChangePassword = () => {
    navigate('/changepassword', {
      state: { document: state.state ? state.state.document : null },
    });
  };

  // useEffect(() => {

  //     if (!localStorage.getItem("access_token_employee")) {
  //         if (state.state) {
  //             navigate('/login', { state: { document: state.state.document } })
  //         }
  //         else {
  //             navigate('/login')
  //         }
  //     }
  // }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    if (isAuthorized(pathname)) {
      getUserProfile();
    } else {
      if (state.state) {
        navigate('/login', {
          state: { document: state.state.document },
        });
      } else {
        navigate('/login');
      }
    }
  }, []);

  return (
    <div>
      {context.user && (
        <div className="container-fluid mt-5">
          <h1 className="question-form mb-5">Mon profil</h1>

          <div className="row col-12 col-lg-8 shadow p-5">
            <div className="col-10 h4 mb-5 nom-validation">
              {context.user.employee_information.firstname[0].toUpperCase() +
                context.user.employee_information.firstname
                  .slice(1, context.user.employee_information.firstname.length)
                  .toLowerCase()}{' '}
              {context.user.employee_information.lastname}
              <br />
              {(context.user && context.user.employee_information.civility === EmployeeCivility.Male && (
                <small> né le </small>
              )) ||
                (context.user && context.user.employee_information.civility === EmployeeCivility.Female && (
                  <small> née le </small>
                ))}{' '}
              <small> {moment(context.user.employee_information.birthday).format('DD/MM/YYYY')} </small>
            </div>
            <div className="col-2">
              <img alt="kerij" src={logo} width="50" />
            </div>
            <div className="text-muted">SOCIETE</div>
            <hr className="hr-kerij col-12" />

            {/* <div className="items-validation row col-12 mb-3">
                        <div className="number-validation col-1">1</div>
                        <div className="item-validation col-10">
                            <div className="title-validation col-12">SOCIETE</div>
                            <div className="data-validation col-12">{context.user.salarie_information.societe.NOM}</div>
                        </div>
                    </div> */}

            <div className="items-validation row col-12 mb-3">
              <div className="data-validation col-12 row">
                <div> {context.user.employee_information.business.name} </div>
              </div>
            </div>

            <div className="text-muted mt-3">CONTACTS</div>
            <hr className="hr-kerij col-12" />

            {/* <div className="items-validation row col-12 mb-3">
                        <div className="number-validation col-1">2</div>
                        <div className="item-validation col-10">
                            <div className="title-validation col-12">EMAIL</div>
                            <div className="data-validation col-12" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                                <div className="col-10">{context.user.MAIL}</div>
                                <FontAwesomeIcon className="fa-1px" icon="fa-pen" style={{cursor: "pointer"}} onClick={handleChangeContactMail}>Modifier Mail</FontAwesomeIcon>
                            </div>
                        </div>
                    </div> */}

            <div className="items-validation row col-12 mb-3">
              <div className="title-validation col-12">EMAIL</div>
              <div className="data-validation col-12 row">
                <div className="col-md-7 col-12">{context.user.email}</div>
                <div className="col-md-5 col-12">
                  <ActionButton actionName="edit" label={'Modifier Mail'} onClick={handleChangeContactMail} />
                </div>
              </div>
            </div>

            {/* <div className="items-validation row col-12 mb-3">
                        <div className="number-validation col-1">3</div>
                        <div className="item-validation col-10">
                            <div className="title-validation col-12">TELEPHONE</div>
                            <div className="data-validation col-12" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                                <div className="col-10">{context.user.TELEPHONE}</div>
                                <FontAwesomeIcon className="fa-1px" icon="fa-pen" style={{cursor: "pointer"}} onClick={handleChangeContactTel}>Modifier Tel</FontAwesomeIcon>
                            </div>
                        </div>
                    </div> */}

            <div className="items-validation row col-12 mb-3">
              <div className="title-validation col-12">TELEPHONE</div>
              <div className="data-validation col-12 row">
                <div className="col-md-7 col-12">{context.user.phone}</div>
                <div className="col-md-5 col-12">
                  <ActionButton actionName="edit" label={'Modifier Tél.'} onClick={handleChangeContactTel} />
                </div>
              </div>
            </div>

            <div className="text-muted mt-3">ADRESSE</div>
            <hr className="hr-kerij col-12" />

            {/* <div className="items-validation row col-12 mb-3">
                        <div className="number-validation col-1">4</div>
                        <div className="item-validation col-10">
                            <div className="title-validation col-12">RUE</div>
                            <div className="data-validation col-12">{context.user.salarie_information.ADRESSE}</div>
                        </div>
                    </div> */}

            <div className="items-validation row col-12 mb-3">
              <div className="title-validation col-12">RUE</div>
              <div className="data-validation col-12 row">
                <div>{context.user.employee_information.address1}</div>
              </div>
            </div>

            {/* <div className="items-validation row col-12 mb-3">
                        <div className="number-empty-validation col-1"></div>
                        <div className="item-validation col-10">
                            <div className="title-validation col-12">CODE POSTAL</div>
                            <div className="data-validation col-12">{context.user.salarie_information.CODE_POSTAL}</div>
                        </div>
                    </div> */}

            <div className="items-validation row col-12 mb-3">
              <div className="title-validation col-12">CODE POSTAL</div>
              <div className="data-validation col-12 row">
                <div>{context.user.employee_information.zipcode}</div>
              </div>
            </div>

            {/* <div className="items-validation row col-12 mb-3">
                        <div className="number-empty-validation col-1"></div>
                        <div className="item-validation col-10">
                            <div className="title-validation col-12">VILLE</div>
                            <div className="data-validation col-12">{context.user.salarie_information.LOCALITE}</div>
                        </div>
                    </div> */}

            <div className="items-validation row col-12 mb-3">
              <div className="title-validation col-12">VILLE</div>
              <div className="data-validation col-12 row">
                <div>{context.user.employee_information.locality}</div>
              </div>
            </div>
          </div>
          <div className="row col-12 col-lg-8 shadow p-5 mb-3 footer-profil text-center">
            {/* <div className="col-lg-6 mb-3">
                        <button type="button" className = "col-12 btn btn-kerij-blanc text-center" onClick={handleChangeContact}>Modifier Tel / Mail</button>
                    </div> */}

            <div className="col-12 mb-3 text-center">
              <button type="button" className="col-lg-6 btn btn-kerij-blanc text-center" onClick={handleChangePassword}>
                Changer de mot de passe
              </button>
            </div>

            <div className="col-12 text-center mt-4">
              <button
                className="btn btn-sm"
                style={{
                  color: 'white',
                  fontWeight: 'bolder',
                  fontSize: '1em',
                }}
                onClick={() => {
                  setModalCGU(true);
                }}
              >
                {`Voir les Conditions Générales d'Utilisation`}
              </button>
            </div>
          </div>

          {/* <hr className="hr-kerij col-5 mb-5"/> */}
          <div className="container-fluid mb-5">
            <button type="button" className="btn" onClick={handleLogout}>
              Se deconnecter
            </button>
          </div>
        </div>
      )}

      <Modal
        style={customStyles as any}
        className="Modal"
        overlayClassName="Overlay"
        closeTimeoutMS={200}
        isOpen={modalCGU}
        onRequestClose={() => {
          setModalCGU(!modalCGU);
        }}
        ariaHideApp={false}
      >
        <div className="float-left">
          <button
            onClick={() => {
              setModalCGU(!modalCGU);
            }}
            className="btn-close exit-modal"
          ></button>
        </div>
        <h3 className="question-form text-center titre-modal">{` Conditions Générales d'Utilisation`}</h3>
        <div className="text-modal mt-3">
          <p className="text-center">
            <strong>CONDITIONS GENERALES D&rsquo;UTILISATION</strong>
          </p>
          <p className="text-center">
            <strong>DERNIERE MISE A JOUR&nbsp;: 03/11/2022</strong>
          </p>
          <p>&nbsp;</p>
          <ol className="text-start" start={1}>
            <li>
              <strong>OBJET ET CHAMPS D&rsquo;APPLICATION</strong>
            </li>
          </ol>
          <ul>
            <li>
              WITHBOT, soci&eacute;t&eacute; par actions simplifi&eacute;e &agrave; associ&eacute; unique au capital
              social de 100.000 &euro;, immatricul&eacute;e sous le num&eacute;ro 880 118 419 au RCS de Paris, dont le
              si&egrave;ge social est situ&eacute; au 128, rue de la Bo&eacute;tie, 75008 Paris (&laquo;&nbsp;
              <strong>WITHBOT</strong>&raquo;, &laquo;&nbsp;
              <strong>nous</strong>&nbsp;&raquo;, &laquo;&nbsp;
              <strong>notre</strong>&nbsp;&raquo;, &laquo;&nbsp;
              <strong>nos</strong>&nbsp;&raquo;) est
              {`l'`}&eacute;diteur de la plateforme KERIJ accessible via <a href="http://www.kerij.fr/">kerij.fr</a> ou
              via tout autre moyen mis &agrave; disposition par l&rsquo;entreprise (la &laquo;&nbsp;
              <strong>Plateforme</strong>&nbsp;&raquo;) plateforme sur laquelle les utilisateurs, peuvent se rendre afin
              de d&rsquo;utiliser les services propos&eacute;s par des tiers sur la Plateforme (les &laquo;&nbsp;
              <strong>Services</strong>
              &nbsp;&raquo;).
            </li>
          </ul>
          <p>
            Nous avons r&eacute;dig&eacute; ces conditions g&eacute;n&eacute;rales d&rsquo;utilisation (ci-apr&egrave;s
            les &laquo;&nbsp;
            <strong>Conditions g&eacute;n&eacute;rales</strong>
            &nbsp;&raquo; ou les &laquo;&nbsp;<strong>CGU</strong>
            &nbsp;&raquo;) afin que vous connaissiez les r&egrave;gles et le fonctionnement de la Plateforme. Ces
            Conditions g&eacute;n&eacute;rales constituent un contrat juridiquement contraignant entre vous
            (&laquo;&nbsp;
            <strong>l&rsquo;Utilisateur</strong>
            &nbsp;&raquo;, &laquo;&nbsp;<strong>vous</strong>
            &nbsp;&raquo;, &laquo;&nbsp;<strong>vos</strong>
            &nbsp;&raquo; ou &laquo;&nbsp;<strong>votre</strong>
            &nbsp;&raquo;) et WITHBOT. Nous vous prions donc les lire attentivement.
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              En utilisant cette Plateforme, vous acceptez les CGU sans r&eacute;serve. Vous &ecirc;tes tenu de vous
              r&eacute;f&eacute;rer &agrave; la version des CGU accessible en ligne &agrave; la date de votre
              acc&egrave;s et de votre utilisation des Services.
            </li>
          </ul>
          <p>
            Nous vous informons que la seule version applicable des CGU est celle qui est accessible via
            [compl&eacute;ter avec le lien URL des CGU].
          </p>
          <p>&nbsp;</p>
          <ol className="text-start" start={2}>
            <li>
              <strong>ACCES A LA PLATEFORME ET DESCRIPTION DES SERVICES</strong>
              <ul>
                <li>
                  <strong>Acc&egrave;s &agrave; la Plateforme</strong>
                </li>
              </ul>
            </li>
          </ol>
          <p>Pour acc&eacute;der et utiliser les Services fournis par la Plateforme, vous confirmez que :</p>
          <ul>
            <li>vous avez la capacit&eacute; de vous engager avec WITHBOT ;</li>
            <li>vous &ecirc;tes salari&eacute; d&rsquo;un client de WITHBOT&nbsp;;</li>
            <li>
              vous &ecirc;tes &acirc;g&eacute; de 18 ans ou plus. Si vous avez moins de 18 ans, vous confirmez que vous
              avez {`l'autorisation`} pr&eacute;alable du titulaire de l&rsquo;autorit&eacute; parentale pour utiliser
              cette Plateforme. Cette autorisation peut &ecirc;tre demand&eacute;e par nos services ;
            </li>
            <li>
              vous vous engagez &agrave; respecter les pr&eacute;sentes Conditions g&eacute;n&eacute;rales ainsi que
              toutes les lois, r&egrave;gles et r&eacute;glementations locales, nationales et internationales
              applicables.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              <strong>Descriptions des fonctionnalit&eacute;s</strong>
            </li>
          </ul>
          <p>La Plateforme propose &agrave; l&rsquo;Utilisateur de&nbsp;:</p>
          <table>
            <tbody>
              <tr>
                <td width="160">
                  <p>Titre</p>
                </td>
                <td width="444">
                  <p>Description</p>
                </td>
              </tr>
              <tr>
                <td width="160">
                  <p>
                    <b>Cr&eacute;er et g&eacute;rer son espace personnel</b>
                  </p>
                </td>
                <td width="444">
                  <p>&nbsp; Cr&eacute;er un compte personnel</p>
                  <p>&nbsp; Acc&eacute;der et modifier ses donn&eacute;es personnelles</p>
                  <p>&nbsp; Acc&eacute;der &agrave; l&rsquo;historique de ses absences</p>
                  <p>&nbsp; Acc&eacute;der &agrave; la base documentaire de ses absences</p>
                </td>
              </tr>
              <tr>
                <td width="160">
                  <p>
                    <b>D&eacute;clarer une absence</b>
                  </p>
                </td>
                <td width="444">
                  <p>
                    &nbsp; D&eacute;clarer les absences relevant d&rsquo;un arr&ecirc;t ou d&rsquo;un accident de
                    travail, d&rsquo;une hospitalisation, d&rsquo;un mi-temps th&eacute;rapeutique, d&rsquo;un
                    cong&eacute; maternit&eacute; ou de parentalit&eacute;, d&rsquo;un d&eacute;c&egrave;s, d&rsquo;un
                    cong&eacute; proche aidant
                  </p>
                  <p>
                    &nbsp; Partager les documents l&eacute;gaux et en particulier CERFA arr&ecirc;t de travail,
                    certificat d&rsquo;hospitalisation, certificat de grossesse
                  </p>
                </td>
              </tr>
              <tr>
                <td width="160">
                  <p>
                    <b>Partager l&rsquo;&eacute;volution de sa situation</b>
                  </p>
                </td>
                <td width="444">
                  <p>
                    &nbsp; Informer sa communaut&eacute; de l&rsquo;&eacute;volution de l&rsquo;arr&ecirc;t gr&acirc;ce
                    &agrave; un syst&egrave;me automatis&eacute; de notifications
                  </p>
                  <p>
                    &nbsp; Mettre &agrave; jour les documents l&eacute;gaux (ex&nbsp;: renouvellement d&rsquo;un
                    arr&ecirc;t de travail&hellip;)
                  </p>
                </td>
              </tr>
              <tr>
                <td width="160">
                  <p>
                    <b>G&eacute;rer ses RDV</b>
                  </p>
                </td>
                <td width="444">
                  <p>
                    &nbsp; Solliciter diff&eacute;rents types d&rsquo;entretiens avec la communaut&eacute; RH,
                    manag&eacute;riale et sant&eacute; au travail de son entreprise. En particulier, solliciter une
                    visite m&eacute;dicale de pr&eacute;-reprise, un entretien de retour au travail, un entretien de
                    liaison
                  </p>
                  <p>&nbsp; G&eacute;rer la prise de RDV (choix du cr&eacute;neau, annulation, rappel du rdv)</p>
                  <p>
                    &nbsp; Interagir avec l&rsquo;entreprise lorsque le RDV est &agrave; l&rsquo;initiative de celle-ci
                  </p>
                </td>
              </tr>
              <tr>
                <td width="160">
                  <p>
                    <b>Acc&eacute;der &agrave; des services</b>
                  </p>
                </td>
                <td width="444">
                  <p>
                    &nbsp; Acc&eacute;der &agrave; une gamme &eacute;largie de services de QVCT mise &agrave;
                    disposition par l&rsquo;entreprise, l&rsquo;assureur pr&eacute;voyance de l&rsquo;entreprise et
                    WITHBOT
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ol className="text-start" start={3}>
            <li>
              <strong>CONDITIONS D&rsquo;ACCES ET D&rsquo;USAGE DE LA PLATEFORME</strong>
              <ul>
                <li>
                  <strong>Acc&egrave;s aux Services</strong>
                </li>
              </ul>
            </li>
          </ol>
          <p>
            Vous pouvez acc&eacute;der et utiliser librement la Plateforme, sous r&eacute;serve de cr&eacute;er un
            Compte Utilisateur ou de vous connecter &agrave; ce Compte Utilisateur.
          </p>
          <ul>
            <li>
              <strong>Cr&eacute;ation et acc&egrave;s au Compte Utilisateur</strong>
            </li>
          </ul>
          <p>
            Pour avoir acc&egrave;s et utiliser certaines sections de la Plateforme, vous devrez cr&eacute;er un Compte
            Utilisateur. Ce Compte Utilisateur est personnel et prot&eacute;g&eacute; par un mot de passe.
          </p>
          <p>
            La cr&eacute;ation {`d'un`} Compte Utilisateur implique le renseignement par l&rsquo;Utilisateur
            d&rsquo;informations personnelles telles que le nom, le pr&eacute;nom, l&rsquo;adresse postale,
            d&eacute;partement de naissance, le num&eacute;ro de t&eacute;l&eacute;phone et l&rsquo;adresse
            &eacute;lectronique, {`qui font l'objet d'un traitement de`}
            donn&eacute;es tel que pr&eacute;vu dans la Politique de confidentialit&eacute;.
          </p>
          <p>
            La cr&eacute;ation {`d'un`} Compte Utilisateur implique &eacute;galement le choix {`d'un`} mot de passe. Il
            est recommand&eacute; {`d'adopter`} un mot de passe robuste (contenant au moins 8 caract&egrave;res dont des
            chiffres, des majuscules et des caract&egrave;res sp&eacute;ciaux).
          </p>
          <p>
            Toute perte, d&eacute;tournement ou utilisation non autoris&eacute;e des identifiants {`d'un`} Utilisateur
            et leurs cons&eacute;quences sont de la seule responsabilit&eacute; de cet Utilisateur. Dans tous les cas
            mentionn&eacute;s ci-dessus, {`l'Utilisateur`} est tenu
            {`d'en`} informer WITHBOT, sans d&eacute;lai, par courrier &eacute;lectronique, en pr&eacute;cisant ses
            identifiants, nom, pr&eacute;noms &agrave; {`l'adresse`}
            suivante&nbsp;: <a href="mailto:security@kerij.fr">security@kerij.fr</a>, afin que WITHBOT puisse
            proc&eacute;der &agrave; la r&eacute;initialisation du Compte Utilisateur.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <strong>Droits accord&eacute;s aux Utilisateurs</strong>
            </li>
          </ul>
          <p>
            WITHBOT accorde &agrave; l&rsquo;Utilisateur une licence personnelle, incessible, non exclusive,
            r&eacute;vocable et non sous-licenciable pour {`l'acc`}&egrave;s et
            {`l'utilisation`} de la Plateforme. Cette licence a pour seul but de vous permettre {`d'utiliser`} la
            Plateforme
            {`d'une`} mani&egrave;re conforme aux pr&eacute;sentes Conditions g&eacute;n&eacute;rales.
          </p>
          <p>
            Toute action allant au-del&agrave; de ces droits
            {`d'acc`}&egrave;s et {`d'utilisation`} et notamment toute reproduction, mise &agrave; disposition de tiers,
            t&eacute;l&eacute;chargement illicite constitue un acte de contrefa&ccedil;on. {`L'Utilisateur s'engage`}{' '}
            &agrave; ne pas utiliser ou mettre en &oelig;uvre un robot ou tout autre moyen automatis&eacute; pour
            acc&eacute;der &agrave; la Plateforme et/ou au contenu, et plus g&eacute;n&eacute;ralement &agrave; ne pas
            tenter de porter atteinte au fonctionnement de la Plateforme.
          </p>
          <p>
            Sauf dans les cas express&eacute;ment autoris&eacute;s par la loi, {`l'Utilisateur`} ne doit pas, ni
            permettre &agrave; autrui de modifier, adapter, traduire, faire de l&rsquo;ing&eacute;nierie
            invers&eacute;e, d&eacute;compiler ou d&eacute;sassembler la Plateforme ou {`l'un`} de ces
            &eacute;l&eacute;ments ou cr&eacute;er des &oelig;uvres d&eacute;riv&eacute;es.
          </p>
          <p>
            Nous vous informons que vous pouvez &agrave; tout moment supprimer votre Compte Utilisateur par courrier
            &eacute;lectronique en pr&eacute;cisant vos identifiants, nom et pr&eacute;noms &agrave; l&rsquo;adresse
            suivante <a href="mailto:contact@kerij.fr">contact@kerij.fr</a> afin d&rsquo;utiliser les Services, afin que
            WITHBOT puisse proc&eacute;der &agrave; la r&eacute;initialisation du Compte Utilisateur.
          </p>
          <ul>
            <li>
              <strong>Obligations des Utilisateurs</strong>
            </li>
          </ul>
          <p>
            L&rsquo;Utilisateur est seul responsable de la saisie de ses donn&eacute;es, WITHBOT ne peut en aucun cas
            &ecirc;tre tenu responsable {`d'une`} information erron&eacute;e. Dans le cas o&ugrave; {`l'Utilisateur`}
            renseigne des informations fausses, inexactes, p&eacute;rim&eacute;es, incompl&egrave;tes, trompeuses ou
            mensong&egrave;res, WITHBOT peut, imm&eacute;diatement et sans pr&eacute;avis ni indemnit&eacute;, suspendre
            ou r&eacute;silier le Compte Utilisateur et refuser {`l'acc`}&egrave;s, temporairement ou
            d&eacute;finitivement, &agrave; tout ou partie des Services fournis par la Plateforme.
          </p>
          <p>
            WITHBOT soutient et d&eacute;fend des valeurs de tol&eacute;rance, de diversit&eacute;, de respect
            {`d'autrui.`}
          </p>
          <p>
            {` L'Utilisateur s'engage ainsi`} &agrave; respecter ces valeurs lors de {`l'utilisation`} de la Plateforme.
            En particulier :
          </p>
          <ul>
            <li>
              &agrave; ne pas tenir de propos discriminatoires, racistes, x&eacute;nophobes, antis&eacute;mites,
              insultants, malveillants, obsc&egrave;nes, illicites ou autres propos violents ou pornographiques, ni
              &agrave; publier de contenus contraires &agrave;
              {`l'ordre`} public ou aux bonnes m&oelig;urs de quelque mani&egrave;re que ce soit dans le cadre de sa
              navigation et de son utilisation du Site ;
            </li>
            <li>
              ne pas diffuser de contenu pouvant &ecirc;tre pr&eacute;judiciable, diffamatoire, non autoris&eacute;,
              malveillant ou portant atteinte &agrave; la vie priv&eacute;e ou aux droits de la publicit&eacute;,
              incitant &agrave; la violence, &agrave; la haine raciale ou ethnique ou pouvant &ecirc;tre qualifi&eacute;
              de grossi&egrave;re ind&eacute;cence ou {`d'incitation`} &agrave; commettre certains crimes ou
              d&eacute;lits ;
            </li>
            <li>
              {` ne pas usurper l'identit&eacute; d'une personne ou
                            d'une entit&eacute; ni pr&eacute;senter de
                            mani&egrave;re inexacte votre affiliation &agrave;
                            une autre personne ou entit&eacute; ;`}
            </li>
            <li>
              ne pas utiliser la Plateforme &agrave; des fins politiques, de propagande ou de pros&eacute;lytisme ;
            </li>
            <li>
              ne pas porter atteinte aux droits de propri&eacute;t&eacute; intellectuelle de WITHBOT sur la Plateforme
              et ses &eacute;l&eacute;ments ;
            </li>
            <li>
              ne pas spammer, intimider, solliciter ou d&eacute;tourner la Plateforme de quelque mani&egrave;re que ce
              soit de sa destination ;
            </li>
            <li>
              ne pas mener {`d'activi`}t&eacute;s ill&eacute;gales, en diffusant des contenus (photographies, images,
              textes, vid&eacute;os) repr&eacute;sentant des mineurs de quelque mani&egrave;re que ce soit ;
            </li>
            <li>de signaler &agrave; WITHBOT tout contenu inappropri&eacute;.</li>
          </ul>
          <p>
            Vous demeurez seul responsable de votre utilisation de la Plateforme. WITHBOT ne peut &ecirc;tre tenu
            responsable de toute perte ou dommage r&eacute;sultant
            {`de l'utilisation de la Plateforme par l'Utilisateur, ou`}
            tout tiers (autoris&eacute; ou non).
          </p>
          <ol className="text-start" start={4}>
            <li>
              <strong>OBLIGATIONS DE WITHBOT</strong>
              <ul>
                <li>
                  {` WITHBOT s'engage`} &agrave; faire ses meilleurs efforts pour assurer
                  {`l'acc`}&egrave;s permanent &agrave; la Plateforme et &agrave; ses Services ainsi que son
                  fonctionnement.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            Toutefois, WITHBOT ne peut &ecirc;tre tenue responsable
            {` de l'indisponibilit`}&eacute; de la Plateforme ou {`d'un`}
            Service en cas {`d'`}&eacute;v&eacute;nements tels que&nbsp;:
          </p>
          <ul>
            <li>
              la maintenance de la Plateforme, y compris lorsque
              {`l'intervention`} de maintenance est effectu&eacute;e par des tiers ;
            </li>
            <li>
              la survenance {} &eacute;v&eacute;nement impr&eacute;visible, insurmontable et ext&eacute;rieur (cas de
              force majeure) ;
            </li>
            <li>d&eacute;faillance du r&eacute;seau internet.</li>
          </ul>
          <p>
            {` L'Utilisateur`} est en outre inform&eacute; des risques inh&eacute;rents &agrave; {`l'utilisation`}
            d&rsquo;internet, notamment en termes de d&eacute;faut de s&eacute;curit&eacute; dans la transmission des
            donn&eacute;es et de continuit&eacute; non garantie de
            {`l'acc`}&egrave;s &agrave; la Plateforme et &agrave; son contenu. En aucun cas, WITHBOT ne pourra
            &ecirc;tre tenue responsable de ces risques et de leurs cons&eacute;quences, pr&eacute;judiciables, quelle{' '}
            {`qu'en`}
            soit {`l'ampleur`}, pour{` l'Utilisateur`}.
          </p>
          <p>
            Il appartient &agrave; chaque Utilisateur de prendre toutes les mesures appropri&eacute;es de fa&ccedil;on
            &agrave; prot&eacute;ger ses propres donn&eacute;es, logiciels et mat&eacute;riels de la contamination par
            des virus ou autres formes {`d'attaques`} pouvant circuler sur la Plateforme.
          </p>
          <ul>
            <li>
              WITHBOT se r&eacute;serve le droit, &agrave; tout moment, de modifier tout ou partie de la Plateforme et
              de ses fonctionnalit&eacute;s, de modifier ou de proposer de nouveaux contenus et Services. En
              particulier, WITHBOT peut mettre &agrave; jour le contenu en fonction de {`l'`}&eacute;volution des
              dispositions l&eacute;gales ou des bonnes pratiques en vigueur.
            </li>
          </ul>
          <p>
            Toute nouvelle fonctionnalit&eacute; sera soumise aux pr&eacute;sentes Conditions g&eacute;n&eacute;rales.
          </p>
          <p>
            {`WITHBOT informe l'Utilisateur, qui l'accepte, qu'il
                        sous-traite l'h`}
            &eacute;bergement du Site &agrave; la soci&eacute;t&eacute; <strong>OVH</strong> dont les serveurs sont
            situ&eacute;s en France.
          </p>
          <p>
            Dans le cas o&ugrave; une faille de s&eacute;curit&eacute; d&eacute;tect&eacute;e par WITHBOT est
            susceptible de compromettre gravement la s&eacute;curit&eacute; de la Plateforme, WITHBOT peut, sans
            pr&eacute;avis, interrompre temporairement
            {`l'acc`}&egrave;s &agrave; la Plateforme afin de rem&eacute;dier &agrave; la faille de
            s&eacute;curit&eacute; dans les meilleurs d&eacute;lais.
          </p>
          <ol className="text-start" start={5}>
            <li>
              <strong>PROPRIETE INTELLECTUELLE DE WITHBOT</strong>
            </li>
          </ol>
          <p>
            Tous les droits de propri&eacute;t&eacute; intellectuelle relatifs &agrave; la Plateforme et &agrave; son
            contenu (textes, images, vid&eacute;os, bases de donn&eacute;es, sons, photographies, noms commerciaux,
            logos, marques, etc. ) sont et restent la propri&eacute;t&eacute; de WITHBOT, ou sont soumis &agrave; des
            licences et/ou autorisations qui nous sont accord&eacute;es par des tiers. L&rsquo;Utilisateur
            s&rsquo;engage &agrave; respecter les droits de propri&eacute;t&eacute; intellectuelle de WITHBOT ainsi que
            les droits des tiers.
          </p>
          <p>
            {` L'Utilisateur `}est uniquement autoris&eacute; &agrave; utiliser la Plateforme et son contenu
            conform&eacute;ment aux Conditions g&eacute;n&eacute;rales.{` L'Utilisateur `}ne peut pas reproduire, mettre
            &agrave; disposition du public, ex&eacute;cuter, publier ou modifier toute partie de la Plateforme et de son
            contenu sans {`l'accord`} &eacute;crit pr&eacute;alable de WITHBOT.
          </p>
          <ol className="text-start" start={6}>
            <li>
              <strong>LIENS VERS DES SITES TIERS</strong>
              <ul>
                <li>
                  La Plateforme peut contenir des liens redirigeant vers {`d'autres`} sites et donn&eacute;es de tiers
                  proposant les services disponibles sur la Plateforme, ces liens seront uniquement fournis &agrave;
                  titre {`d'information`}, sans que WITHBOT n&rsquo;ait aucun contr&ocirc;le sur le contenu ou les
                  donn&eacute;es de ces sites.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            Dans la mesure o&ugrave; WITHBOT ne peut contr&ocirc;ler ces sites et ces sources externes, il ne peut
            &ecirc;tre tenu pour responsable de la mise &agrave; disposition de ces sites et sources externes, et ne
            peut supporter aucune responsabilit&eacute; quant au contenu, publicit&eacute;s, produits, services ou tout
            autre mat&eacute;riel disponible sur ou &agrave; partir de ces sites ou sources externes.
          </p>
          <ul>
            <li>
              De plus, WITHBOT ne pourra &ecirc;tre tenu responsable de tous dommages ou pertes av&eacute;r&eacute;s ou
              all&eacute;gu&eacute;s cons&eacute;cutifs ou en{' '}
              {`relation avec l'utilisation
                            ou avec le fait d'avoir`}{' '}
              fait confiance au contenu, &agrave; des biens ou des services disponibles sur ces sites ou sources
              externes.
            </li>
          </ul>
          <ol className="text-start" start={7}>
            <li>
              <strong>RESPONSABILITE</strong>
              <ul>
                <li>
                  <strong>Responsabilit&eacute; g&eacute;n&eacute;rale</strong>
                </li>
              </ul>
            </li>
          </ol>
          <p>
            WITHBOT {`s'efforce`} de maintenir la Plateforme en &eacute;tat de marche et sans interruption ni
            suspension. Mais nous ne pouvons pas promettre un fonctionnement continu de la Plateforme sans aucun
            probl&egrave;me.
          </p>
          <p>
            {`L'Utilisateur reconna&icirc;t &eacute;galement que la
                        Plateforme est fournie &laquo;&nbsp;telle
                        quel&nbsp;&raquo; et &laquo;&nbsp;telle que
                        disponible&nbsp;&raquo; sans garantie d'aucune sorte. En
                        outre, bien que WITHBOT s'efforce de fournir une bonne
                        exp&eacute;rience &agrave; l'Utilisateur, nous ne
                        pouvons affirmer ou garantir que : (a) la Plateforme
                        sera toujours s&eacute;curis&eacute;e ou sans erreur ou
                        alt&eacute;rations ; (b) la Plateforme fonctionnera
                        toujours sans retard, perturbation ou imperfection.`}
          </p>
          <ol className="text-start" start={8}>
            <li>
              <strong>DROIT APPLICABLE ET JURIDICTION COMPETENTE</strong>
              <ul>
                <li>
                  Les pr&eacute;sentes CGU, leur formation, interpr&eacute;tation, ex&eacute;cution, r&eacute;siliation
                  ou expiration sont r&eacute;gies par les lois fran&ccedil;aises.
                </li>
                <li>
                  EN CAS DE LITIGE, NOUS VOUS REMERCIONS DE VOUS ADRESSER EN PRIORITE AU SERVICE CLIENT DE WITHBOT EN
                  APPELANT AU 06 45 20 96 21 OU PAR COURRIEL A : <a href="mailto:contact@kerij.fr">contact@kerij.fr</a>
                  {` . TOUT LITIGE ENTRE WITHBOT ET L'UTILISATEUR
                                    DECOULANT DE L'INTERPRETATION, DE
                                    L'EXECUTION OU DE LA RESILIATION DES
                                    PRESENTES CGU QUI NE PEUT ETRE RESOLU A
                                    L'AMIABLE SERA REGLE PAR LES TRIBUNAUX
                                    COMPETENTS DU RESSORT DE LA COUR`}
                  D&rsquo;APPEL DE PARIS, NONOBSTANT TOUTE RECLAMATION OU GARANTIE INCIDENTE, OU EN CAS DE PLURALITE DE
                  DEFENDEURS.
                </li>
              </ul>
            </li>
            <li>
              <strong>DIVERS</strong>
              <ul>
                <li>
                  Les pr&eacute;sentes Conditions g&eacute;n&eacute;rales, constituent l&rsquo;int&eacute;gralit&eacute;
                  de l&rsquo;accord entre les parties et annulent et remplacent tous autres documents
                  &eacute;chang&eacute;s ou accords, &eacute;crits ou verbaux, ant&eacute;rieurement conclus entre les
                  Parties.
                </li>
                <li>
                  {`Si WITHBOT ne tire pas profit ou n'applique
                                    pas une des dispositions des CGU, cela ne
                                    peut &ecirc;tre interpr&eacute;t&eacute; par
                                    l'Utilisateur comme une renonciation
                                    &agrave; celles-ci.`}
                </li>
                <li>
                  Nous nous r&eacute;servons tous les droits qui ne vous sont pas express&eacute;ment accord&eacute;s.
                </li>
                <li>
                  Vous ne pouvez transf&eacute;rer aucun de vos droits ou obligations en vertu des pr&eacute;sentes CGU
                  sans notre consentement.
                </li>
                <li>
                  Les pr&eacute;sentes CGU peuvent &ecirc;tre modifi&eacute;es par WITHBOT afin de prendre en compte,
                  notamment les &eacute;volutions r&eacute;glementaires, les am&eacute;liorations apport&eacute;es au
                  Site, et les changements de situation. L&rsquo;Utilisateur est invit&eacute; &agrave; consulter
                  r&eacute;guli&egrave;rement cette page.
                </li>
              </ul>
            </li>
          </ol>
        </div>

        <div className="text-center"></div>
      </Modal>
    </div>
  );
}

export default ProfilUI;
