import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchRhRole } from '@/services/rh';
import NewRoleModal from './NewRoleModal';
import { RHRole } from '@/types';
import { PermissionBox } from '@/components/PermissionBox';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { ActionButton } from '@/components/ActionButton';
import { RHPage } from '@/components/RHPage';

export const AdminRoles = () => {
  const [listRoles, setListRoles] = useState<RHRole[]>([]);
  const [modalIsOpenAddNewRole, setModalIsOpenAddNewRole] = useState(false);
  const [selectedRole, setSelectedRole] = useState<RHRole | undefined>(undefined);

  function retrieveRoles() {
    fetchRhRole()
      .then((response) => {
        const updatedRoles = response.data.map((role_name: any) => ({
          ...role_name,
        }));
        setListRoles(updatedRoles);
      })
      .catch((error) => {
        console.error("Une erreur s'est produite :", error);
      });
  }

  useEffect(() => {
    retrieveRoles();
  }, []);

  return (
    <PermissionBox scope="admin" action="access" className="col-12">
      <RHPage
        title="Gérer les rôles"
        actions={
          <PermissionBox scope="admin" action="edit">
            <ActionButton
              variant={'contained'}
              color="primary"
              actionName={'add'}
              onClick={() => setModalIsOpenAddNewRole(true)}
            />
          </PermissionBox>
        }
      >
        <div className="gerer-user text-start col-12 "></div>
        <List>
          {listRoles.map((value, index) => (
            <ListItem key={index} disablePadding>
              <Tooltip title={value.is_admin ? 'Ce rôle ne peut pas être modifié' : undefined}>
                <ListItemButton
                  style={value.is_admin ? { cursor: ' not-allowed' } : undefined}
                  onClick={
                    value.is_admin
                      ? undefined
                      : () => {
                          setSelectedRole(value);
                          setModalIsOpenAddNewRole(true);
                        }
                  }
                >
                  <ListItemIcon>
                    <FontAwesomeIcon className="fa-solid" icon={value.is_admin ? 'user-lock' : 'user-pen'} />
                  </ListItemIcon>
                  <ListItemText primary={value.role_name} />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>

        <NewRoleModal
          isOpen={modalIsOpenAddNewRole}
          role={selectedRole}
          onModalClosed={() => {
            retrieveRoles();
            setModalIsOpenAddNewRole(false);
            setSelectedRole(undefined);
          }}
        />
      </RHPage>
    </PermissionBox>
  );
};
