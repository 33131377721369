import React from 'react';
import { Box } from '@mui/material';
import { ActionButton, ActionButtonProps } from '@/components/ActionButton';
import { Spacer } from '@/components/Spacer';

interface AbsencesFormSubmitProps {
  actionName: ActionButtonProps['actionName'];
  disabledConfirm?: boolean;
  disabledCancel?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const AbsencesFormSubmit = ({
  actionName,
  disabledConfirm,
  disabledCancel,
  onConfirm,
  onCancel,
}: AbsencesFormSubmitProps) => {
  return (
    <Box>
      <Spacer />
      <ActionButton variant="text" actionName="previous" disabled={disabledCancel} onClick={onCancel} sx={{ mr: 1 }} />

      <ActionButton actionName={actionName} disabled={disabledConfirm} onClick={() => onConfirm()} sx={{ mr: 1 }} />
      <Spacer />
    </Box>
  );
};
