import React, { useMemo } from 'react';
import { ArrowDropDownRounded, ArrowDropUpRounded, FilterAltRounded } from '@mui/icons-material';
import { Button as CustomButton, ButtonProps } from '@/components/Button';
import { buttonClasses, styled, typographyClasses } from '@mui/material';

interface FilterButtonProps extends Omit<ButtonProps, 'sx' | 'children'> {
  open?: boolean;
}

export const FilterButton = (props: FilterButtonProps): JSX.Element => {
  const { open, ...buttonProps } = props;

  const iconMenu = useMemo(
    () =>
      open ? (
        <ArrowDropUpRounded sx={(theme) => ({ color: theme.palette.common.black })} />
      ) : (
        <ArrowDropDownRounded sx={(theme) => ({ color: theme.palette.common.black })} />
      ),
    [open],
  );

  return (
    <Button
      disableRipple
      variant="contained"
      color="inherit"
      startIcon={<FilterAltRounded />}
      endIcon={iconMenu}
      {...buttonProps}
    >
      Filtrer
    </Button>
  );
};

const Button = styled(CustomButton)(({ theme }) => ({
  minHeight: 40,
  marginLeft: theme.spacing(1.5),
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[1],
  border: '1px solid',
  borderColor: theme.shape.borderColor,
  color: theme.palette.primary.dark,
  fontWeight: 400,
  padding: theme.spacing(1),
  [`& .${buttonClasses.endIcon}`]: {
    marginLeft: theme.spacing(0.5),
    marginRight: 0,
  },
  [`& .${buttonClasses.startIcon}`]: {
    marginRight: theme.spacing(0.5),
    marginLeft: 0,
  },
  [`& .${typographyClasses.root}`]: {
    textAlign: 'left',
    minWidth: theme.spacing(5.75),
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.colorScheme.outlineVariant,
  },
  '&:active': {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.colorScheme.outlineVariant,
    boxShadow: theme.shadows[0],
  },
}));
