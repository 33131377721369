import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { employeeSendCode, employeeVerifyContact } from '../../../services/employee';
import { AccountContext, useAppSelector } from '@/store';
import validator from 'validator';
import { useForm } from 'react-hook-form';
import { ActionButton } from '@/components/ActionButton';

export interface ModifyContactTelForm {}

export function ModifyContactTelForm(props: ModifyContactTelForm) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector(AccountContext.getUser);
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      phone: '',
    },
  });
  const idUser = location.state?.data?.id || (user ? user.employee_information.id : null);
  useEffect(() => {
    if (!location.state) {
      navigate('/login');
    }
  }, []);

  const handleFormSubmit = handleSubmit(async (formData) => {
    try {
      await employeeSendCode({
        phone: formData.phone,
      });
      const name =
        location.state?.data?.employee_information.lastname || (user ? user.employee_information.firstname : null);
      const page = location.state?.page;
      const document = location.state?.document || {};
      navigate('/confirmation', {
        state: {
          id: idUser,
          mail: null,
          telephone: formData.phone,
          ...location.state.data,
          nom: name,
          page,
          document,
          location: location.pathname,
        },
      });
    } catch (error) {
      alert(error);
    }
  });

  return (
    <div>
      <div className="container-fluid">
        <form onSubmit={handleFormSubmit} className="form-legacy mt-5 mb-5 col-lg-4">
          <h3 className="question-form mb-5">Informations de contact</h3>

          <div className="msg-pass">
            <em>
              {`Ces informations permettent à KERij de vous
                            accompagner tout au long de vos parcours d'absences.
                            Elles ne seront, en aucun cas, transmises à votre
                            employeur.`}
            </em>
          </div>

          <label>
            <input
              type="tel"
              placeholder="Téléphone"
              {...register('phone', {
                required: true,
                validate: {
                  phone: (value) => validator.isMobilePhone(value, 'fr-FR') || `Ce champ doit être un numéro mobile`,
                  unique: async (value) => {
                    const response = await employeeVerifyContact({
                      phone: value,
                      idEmployeeLogin: idUser,
                    });
                    return response.data.hasExisted ? "Ce numéro est déjà présent dans l'application" : true;
                  },
                },
              })}
            />
            <div className="err">{errors?.phone?.message}</div>
          </label>

          <div className="err">{errors?.root?.message}</div>

          <ActionButton color="primary" type="submit" disabled={isSubmitting} actionName="validate" startIcon={null} />
        </form>
      </div>
    </div>
  );
}
