import { Box, Typography } from '@mui/material';
import CustomModal from '../../../../../../base/CustomModal';
import React from 'react';
import { colors } from '../../../../../../../constants';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import { Button } from '@/components/Button';
import { ActionButton } from '@/components/ActionButton';

const dataOption = [
  {
    value: '1',
    label: "Le collaborateur s'est engagé à transmettre le justificatif",
  },
  {
    value: '2',
    label: 'La situation du collaborateur ne lui permet pas de transmettre le justificatif',
  },
  {
    value: '3',
    label: 'Autre',
  },
];

interface IAcceptAbsenceModalProperties {
  visible: boolean;
  setVisible: (value: boolean) => void;
  onAcceptAbsence?: (description?: string) => void;
}

const AcceptAbsenceModal = ({ visible, setVisible, onAcceptAbsence }: IAcceptAbsenceModalProperties) => {
  const [absenceOption, setAbsenceOption] = React.useState<any>(null);
  return (
    <CustomModal
      open={visible || false}
      setOpen={setVisible}
      renderHeader={() => `Accepter l'absence`}
      renderFooter={() => (
        <Box textAlign="right">
          <ActionButton actionName={'cancel'} onClick={() => setVisible(false)} sx={{ mr: 1 }} />
          <ActionButton
            disabled={absenceOption === null}
            actionName={'validate'}
            label={'Confirmer'}
            onClick={() => onAcceptAbsence?.(dataOption.find((data) => data.value === absenceOption)?.label)}
          />
        </Box>
      )}
      renderContent={() => <Content onChoiceSelected={setAbsenceOption} />}
    />
  );
};

interface IContentProperties {
  onChoiceSelected: (choice: string) => void;
}

const Content = ({ onChoiceSelected }: IContentProperties) => {
  return (
    <Box sx={{ overflowY: 'hidden' }}>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={(e) => {
            onChoiceSelected(e.target.value);
          }}
        >
          {dataOption.map((item, index) => {
            return (
              <FormControlLabel
                style={{
                  color: colors.darkblue,
                }}
                key={index}
                value={item.value}
                control={
                  <Radio
                    style={{
                      color: colors.darkblue,
                    }}
                  />
                }
                label={<Typography fontSize={14}>{item.label}</Typography>}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default AcceptAbsenceModal;
