import * as React from 'react';
import classNames from 'clsx';
import {
  Box,
  BoxProps,
  Divider,
  type DividerProps,
  List,
  ListItemText,
  ListItemTextProps,
  type ListProps,
  Paper,
  type PaperProps,
  styled,
  Typography,
} from '@mui/material';

const componentName = 'PageSectionList';

export interface PageSectionListProps extends BoxProps {
  sectionTitle?: React.ReactNode;
  PaperProps?: PaperProps;
  ListProps?: ListProps;
  DividerProps?: (child: React.ReactElement, childIndex: number) => DividerProps;
}

export function PageSectionList(props: PageSectionListProps) {
  const {
    className,
    children,
    PaperProps,
    ListProps,
    DividerProps = PageSectionList.defaultProps.DividerProps,
    sectionTitle,
    ...otherProps
  } = props;

  const transformedChildren = React.Children.toArray(children).flatMap((child, childIndex) => {
    if (React.isValidElement(child)) {
      if (childIndex === 0) return [child];
      const dividerProps = DividerProps(child, childIndex);
      return [<Divider key={`divider-${childIndex}`} {...dividerProps} />, child];
    }

    return [];
  });

  return (
    <Root className={classNames(componentName, className)} {...otherProps}>
      {sectionTitle ? (
        <Typography variant="headline" color="primary" className={classNames(`${componentName}__Title`)}>
          {sectionTitle}
        </Typography>
      ) : undefined}
      <Paper {...PaperProps} style={{ overflow: 'hidden', ...PaperProps?.style }}>
        <List className={classNames(`${componentName}__List`)} dense {...ListProps}>
          {transformedChildren}
        </List>
      </Paper>
    </Root>
  );
}
PageSectionList.defaultProps = {
  DividerProps: (child: React.ReactElement, childIndex: number) => {
    const listItemText = React.Children.toArray(child.props.children).find(
      (_) => React.isValidElement(_) && _.type == ListItemText,
    ) as undefined | React.ReactElement<ListItemTextProps>;
    return {
      variant: listItemText?.props?.inset ? ('inset' as const) : undefined,
    };
  },
};

const Root = styled(Box, {
  name: componentName,
})(({ theme }) => ({
  [`& .${componentName}__Title`]: {
    marginBottom: theme.spacing(1),
  },
  [`& .${componentName}__List`]: {
    padding: 0, // No padding inside a list
  },
  [`& .MuiListItemText-root`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
