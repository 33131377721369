import React from 'react';
import { Link } from 'react-router-dom';

import './Activites.css';
import ImgActivites from '../../assets/img-activite-login.svg';
import { ActionButton } from '@/components/ActionButton';

function ActivitesLogin() {
  return (
    <div>
      <div className="container-fluid mt-5">
        <div className="col-12 col-lg-10 px-3 text-center">
          <h1 className="question-form mb-5">Votre activité</h1>

          <img alt="kerij" className="mb-5" src={ImgActivites} />

          <h2>Suivez vos absences et vos notifications</h2>

          <div>
            Nous vous accompagnons dans le suivi des étapes de votre arrêt, de votre déclaration au retour au poste.
          </div>

          <div className="position-relative col-12 text-center mt-5 mb-3">
            <Link className="btn-link" to="/login" state={{ page: '/activites' }}>
              <ActionButton color="primary" size="large" label={'Se connecter'} sx={{ pl: 10, pr: 10 }} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivitesLogin;
