import React, { useEffect, useState } from 'react';
import imgActions from '@/assets/img-actions-dashboard.svg';
import imgNoActions from '@/assets/img-no-actions-dashboard.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDataActions } from '@/shared/services/getDataEvents.service';
import { useError } from '@/shared/useError';
import { PRO_URL } from '@/constants/urls.constants';
import { getFullName } from '@/shared/utils/getFullName.service';
import { Card, CardActions, CardHeader, CircularProgress } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useAppDispatch, useAppSelector, RHNotification } from '@/store';
import { RHNotificationAssociationType, type RHNotification as RHNotificationType } from '@/types';
import { IfPermission } from '@/components/IfPermission';
import { ListCardContent } from '../ListCard';
import { ActionButton } from '@/components/ActionButton';

const ToDoAction = () => {
  const dispatch = useAppDispatch();

  const listSelectBusiness = useAppSelector((state) => state.rhNavbarContext?.listSelectBusiness);
  const selectedIdBusiness = useAppSelector((state) => state.rhNavbarContext?.selectedIdBusiness);
  const { error, triggerError, clearError } = useError();
  const [data, setData] = useState<RHNotificationType[]>([]);

  const total = useAppSelector(RHNotification.getTotal);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchActions = async () => {
      clearError();
      try {
        setLoading(true);
        const data = await getDataActions({
          dateDebut: '',
          dateFin: '',
          search: '',
          listSelectBusiness: listSelectBusiness,
          selectedIdBusiness: selectedIdBusiness,
          offset: 0,
          limit: 4,
        });
        setData(data.actions);
        dispatch(RHNotification.actions.setTotal(data?.length || 0));
        setLoading(false);
      } catch {
        setLoading(false);
        triggerError();
      }
    };
    fetchActions();
  }, [listSelectBusiness, selectedIdBusiness]);

  const location = useLocation();

  const content =
    data.length > 0 ? (
      data.slice(0, 4).map((value) => (
        <TableRow
          key={value.id}
          style={{ cursor: 'pointer', border: '0' }}
          onClick={() => {
            navigate(PRO_URL.PREFIXE_PRO + PRO_URL.ACTIONS, {
              state: {
                document: {
                  idSalarie: value.employee_information?.id,
                  // @ts-ignore type not correct
                  idBusiness: value.business?.id,
                },
              },
              // @ts-ignore type not correct
              previousPath: location.pathname,
            });
          }}
        >
          <TableCell>
            {value.employee_information ? (
              <span>{getFullName(value.employee_information)}</span>
            ) : (
              <span>{value.type_notification}</span>
            )}
          </TableCell>

          <TableCell>
            {value.employee_information && value.max_level && value.current_level ? (
              <span className={value.action === 'refused' ? 'pastille-rouge' : 'pastille-verte'}>{`${Number.parseInt(
                // @ts-ignore type not correct
                value.current_level,
              )}/${value.max_level}`}</span>
            ) : (
              ''
            )}
          </TableCell>
          {value.association_type === RHNotificationAssociationType.Absence && value.rh_notification_histories && (
            <TableCell>
              {value.rh_notification_histories &&
                value.rh_notification_histories.length > 0 &&
                // @ts-ignore type not correct
                value.rh_notification_histories
                  // @ts-ignore type not correct
                  .sort((a, b) => new Date(b.date_notification) - new Date(a.date_notification))
                  .at(0).action}
              {/*&nbsp;{' '}*/}
              {/*<FontAwesomeIcon*/}
              {/*    icon="fa-solid fa-arrow-right"*/}
              {/*    style={{ cursor: 'pointer' }}*/}
              {/*/>*/}
            </TableCell>
          )}

          {value.association_type === RHNotificationAssociationType.Parcours && (
            <TableCell>Déclenchement de parcours</TableCell>
          )}
        </TableRow>
      ))
    ) : (
      <div className="mt-5">
        <div className="col-12 text-center">
          <img src={imgNoActions} />
        </div>
        <div className="col-12 text-center">
          <strong>{`Vous n'avez aucune action à réaliser pour le moment`}</strong>
          <div className="col-12 text-center">
            N’hésitez pas à consulter toutes les absences pour savoir quelles vont être les prochaines étapes.
          </div>
        </div>
      </div>
    );

  const allActionsButton = total > 4 && (
    <IfPermission scope="absence-management" action="edit">
      <ActionButton
        color="secondary"
        actionName="next"
        label={'Toutes les actions à réaliser'}
        onClick={() => {
          navigate(PRO_URL.PREFIXE_PRO + PRO_URL.ACTIONS);
        }}
      />
    </IfPermission>
  );

  return (
    <Card className="col-12" sx={{ minHeight: 350 }}>
      <CardHeader
        avatar={<img src={imgActions} />}
        className={'header-actions'}
        title={<>Actions à réaliser {total > 0 && <span className="pastille-nb-actions text-end">{total}</span>}</>}
        // subheader="September 14, 2016"
      />
      <ListCardContent>
        {loading && (
          <div className="col-12 text-center">
            <CircularProgress />
          </div>
        )}

        {!loading && (
          <TableContainer>
            <Table>
              <TableBody>{content}</TableBody>
            </Table>
          </TableContainer>
        )}
      </ListCardContent>
      <CardActions disableSpacing>{allActionsButton}</CardActions>
    </Card>
  );
};

export default ToDoAction;
