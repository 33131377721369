import * as React from 'react';
import { Parcours } from '@/types';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

export interface ParcoursFrequencyInputProps {
  value: Parcours['script']['frequency'];
  onChange: (value: Parcours['script']['frequency']) => void;
}

export function ParcoursFrequencyInput(props: ParcoursFrequencyInputProps) {
  const { value, onChange } = props;

  return (
    <FormControl>
      <FormLabel>Fréquence d’envoi</FormLabel>
      <RadioGroup
        value={value?._ ?? 'none'}
        onChange={(_, value) => {
          if (value === 'franchise') onChange({ _: 'franchise' });
          // eslint-disable-next-line unicorn/no-useless-undefined
          else onChange(undefined);
        }}
      >
        {[
          { value: 'none' as const, label: 'À chaque échéance' },
          { value: 'franchise' as const, label: 'Période de franchise entre deux envois' },
        ].map((option) => {
          return <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />;
        })}
      </RadioGroup>
    </FormControl>
  );
}
