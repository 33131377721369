import { Box, Typography } from '@mui/material';
import CustomModal from '../../../../../../base/CustomModal';
import React, { useState } from 'react';
import { AbsencesDeleteCausePicker } from '../../../../../AbsencesDeleteCausePicker';
import { ActionButton } from '@/components/ActionButton';

interface DeleteAbsenceModalProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  onDeleteAbsence?: (description?: string | null) => void;
}

export const DeleteAbsenceModal = ({ visible, setVisible, onDeleteAbsence }: DeleteAbsenceModalProps) => {
  const [causeValue, setCauseValue] = useState<string | null>(null);
  const [customCauseValue, setCustomCauseValue] = useState<string | null>(null);
  const [deleteCause, setDeleteCause] = useState<string | null | undefined>();

  return (
    <CustomModal
      open={visible || false}
      setOpen={setVisible}
      renderHeader={() => `Supprimer l'absence`}
      renderFooter={() => (
        <Box textAlign="right">
          <ActionButton actionName={'cancel'} onClick={() => setVisible(false)} sx={{ mr: 1 }} />
          <ActionButton
            actionName={'delete'}
            disabled={causeValue === null}
            onClick={() => onDeleteAbsence?.(deleteCause)}
          />
        </Box>
      )}
      renderContent={() => (
        <Box
          sx={(theme) => ({
            color: theme.palette.primaryBackground.contrastText,
          })}
        >
          <Typography fontWeight={600} fontSize={14} mb={1.5}>
            {`Vous êtes sur le point de supprimer une absence. Merci d'en préciser le motif.`}
          </Typography>

          <AbsencesDeleteCausePicker
            value={causeValue}
            initialCustomValue={customCauseValue}
            onChange={({ itemValue, customValue, label }) => {
              setCauseValue(itemValue);
              setCustomCauseValue(customValue);
              setDeleteCause(label);
            }}
          />
        </Box>
      )}
    />
  );
};
