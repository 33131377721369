import * as React from 'react';
import classNames from 'clsx';
import { Parcours } from '@/types/Parcours';
import { ListItem, ListItemAvatar, ListItemButton, type ListItemProps, ListItemText } from '@mui/material';
import moment from 'moment';
import { FormatDate } from '@/constants/date.constants';
import { ParcoursAvatar } from '@/components/ParcoursAvatar';
import { ParcoursSwitchActive } from '@/components/ParcoursSwitchActive';
import { ParcoursOwner } from '@/types';

const componentName = 'ParcoursListItem';

export interface ParcoursListItemProps extends ListItemProps {
  parcours: Parcours;
  onAction?: (type: 'edit' | 'delete' | 'detail', parcours: Parcours) => void;
}

export function ParcoursListItem(props: ParcoursListItemProps) {
  const { parcours, onAction, ...otherProps } = props;
  const separator = ' • ';

  const createdBy =
    parcours.owner === ParcoursOwner.Admin
      ? `par l'équipe KERij${separator}`
      : parcours.created_by == null
      ? ''
      : `par ${parcours.created_by}${separator}`;

  const updatedBy = parcours.edited_by == null ? '' : `par ${parcours.edited_by}`;
  const updatedAt = `modifié le ${moment(parcours.updated_at).format(FormatDate.FRENCH_SHORT_DATE)} ${updatedBy}`;

  return (
    <ListItem
      secondaryAction={<ParcoursSwitchActive value={parcours} />}
      className={classNames(componentName, componentName)}
      disablePadding
      {...otherProps}
    >
      <ListItemButton disableRipple>
        <ListItemAvatar>
          <ParcoursAvatar parcours={parcours} />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{
            fontWeight: 600,
            onClick: () => onAction?.('detail', parcours),
            sx: (theme) => ({
              ':hover': { textDecoration: 'underline' },
              cursor: 'pointer',
            }),
          }}
          primary={parcours.name}
          secondary={[createdBy, updatedAt]}
          sx={(theme) => ({ paddingRight: theme.spacing(2.5), cursor: 'default' })}
        />
      </ListItemButton>
    </ListItem>
  );
}
