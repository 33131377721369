import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { rhChangePassword, rhVerifyPassword } from '../../../services/rh';
import CustomModal from '../../base/CustomModal';
import ChangePasswordModalInput from './ChangePasswordModalInput';
import { regexPassword } from '../../../utils/regex';
import { useToast } from '@/components/Toast';
import { ActionButton } from '@/components/ActionButton';
import { Box } from '@mui/material';

const handleCheckPassword = async (oldPassword: string) => {
  if (oldPassword) {
    const res = await rhVerifyPassword({
      password: oldPassword,
    });
    return !!res?.data?.isValid;
  } else {
    return false;
  }
};
const ChangePasswordModal = ({ isVisible, onClose }: any) => {
  const toast = useToast();

  const {
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    reset,
  } = useForm();

  const [message, setMessage] = useState('');

  const handleClose = () => {
    reset();
    setMessage('');
    onClose();
  };

  const onSubmit = async (data: any) => {
    const valid = await handleCheckPassword(data.oldpassword);
    try {
      if (valid) {
        rhChangePassword({
          password: data.newpassword,
        }).then(async (res) => {
          handleClose();
          toast.present({ message: 'Votre mot de passe a été modifié avec succès', severity: 'success' });
        });
      } else {
        setMessage('Le mot de passe est erroné');
      }
    } catch {
      toast.present({ message: 'Erreur lors du changement de mot de passe', severity: 'error' });
    }
  };
  return (
    <CustomModal
      open={isVisible}
      setOpen={handleClose}
      showExitIcon={true}
      renderFooter={() => <ActionButton actionName="validate" onClick={handleSubmit(onSubmit)} />}
      renderHeader={() => <>Modifier password</>}
      renderContent={() => (
        <div
          className="form-legacy"
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ChangePasswordModalInput
            name="oldpassword"
            label="Mot de passe actuel"
            rules={{
              required: 'Password requis',
              pattern: {
                value: regexPassword,
                message: `Vous devez respecter les critères de complexité du mot de passe`,
              },
            }}
            handleChange={() => setMessage('')}
            control={control}
          />
          {message && <div className="err">{message.toString()}</div>}
          <div className="msg-pass">
            <em>
              Votre nouveau mot de passe doit être composé de 8 caractères minimum, au moins une lettre majuscule, au
              moins une lettre minuscule, et une valeur numérique.
            </em>
          </div>
          <ChangePasswordModalInput
            name="newpassword"
            label="Nouveau mot de passe"
            rules={{
              required: 'Password requis',
              pattern: {
                value: regexPassword,
                message: `Vous devez respecter les critères de complexité du mot de passe`,
              },
            }}
            control={control}
          />

          <ChangePasswordModalInput
            name="confirmpassword"
            label="Confirmation du mot de passe"
            rules={{
              required: 'Password requis',
              pattern: {
                value: regexPassword,
                message: `Vous devez respecter les critères de complexité du mot de passe`,
              },
              validate: () =>
                getValues('newpassword') === getValues('confirmpassword') || 'Les mots de passe ne correspondent pas',
            }}
            control={control}
          />
        </div>
      )}
    ></CustomModal>
  );
};

export default ChangePasswordModal;
