import { ButtonProps } from '@mui/material';

export const ButtonTertiaryStyle: ButtonProps['sx'] = (theme) => {
  return {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&:active': {
      backgroundColor: theme.palette.grey[200],
    },
  };
};
