/* eslint-disable xss/no-mixed-html */
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { DefaultLayout } from '@/components/layouts';
import { StepProgressBar } from '@/features/declaration/components/bars/StepProgressBar';
import { verifyAbsenceMat } from '@/features/declaration/services/verify_absence_mat.service';
import { NavigationContext } from '@/components/Context/NavigationContext';
import { NavigationSteps } from '@/components/Context/NavigationProvider';
import { KerijBox, KerijBoxType } from '@/components/base';
import { AccountContext, useAppFeature, useAppSelector } from '@/store';
import { EmployeeCivility, Absence } from '@/types';
import { Spacer } from '@/components/Spacer';
import { Button } from '@/components/Button';
import { ActionButton } from '@/components/ActionButton';

const getAbsenceType2InitValue = (document: any) => {
  return document.absenceType2 ? document.absenceType2.value : '1';
};

const getAbsenceType2InitLabel = (document: any) => {
  if (document.absenceType2) {
    return document.absenceType2.label;
  } else {
    const concatAbsence = ((document?.absenceType?.value as string) + document?.absenceType1?.value) as string;
    if (concatAbsence === '11') {
      return 'Aucun lien';
    } else if (['12', '22'].includes(concatAbsence)) {
      return 'Accident de travail';
    } else {
      return '';
    }
  }
};

const getAbsenceType2Component = (
  document: any,
  user: any,
  options: {
    absenceType2: string;
    setAbsenceType2: React.Dispatch<React.SetStateAction<string>>;
    setAbsenceType2Label: React.Dispatch<React.SetStateAction<string>>;
    errorPregnancy: boolean;
    setErrorPregnancy: React.Dispatch<React.SetStateAction<boolean>>;
  },
) => {
  const concatAbsence = ((document?.absenceType?.value as string) + document?.absenceType1?.value) as string;
  let title, selections: { value: string; label: string }[];

  switch (concatAbsence) {
    case '11': {
      title = 'Votre maladie est-elle en lien avec une des situations suivantes ?';
      selections = [
        {
          value: '1',
          label: 'Aucun lien',
        },
        {
          value: '2',
          label: 'Accident de travail/trajet – Maladie professionnelle',
        },
        {
          value: '3',
          label: 'Accident de la vie privée impliquant un tiers',
        },
        {
          value: '5',
          label: 'État pathologique résultant de la grossesse',
        },
      ];
      if (user?.employee_information?.civility !== EmployeeCivility.Female) {
        selections = selections.filter((selection) => selection.value !== '5');
      }
      break;
    }
    case '12': {
      title = "Quel est le type d'accident ?";
      selections = [
        {
          value: '1',
          label: 'Accident de travail',
        },
        {
          value: '2',
          label: 'Accident de trajet',
        },
        {
          value: '3',
          label: 'Maladie professionnelle',
        },
      ];
      break;
    }
    case '13': {
      title = 'Votre maladie a-t-elle un lien avec une pathologie antérieure ou une maternité ?';
      selections = [
        {
          value: '1',
          label: 'Aucun lien avec une pathologie antérieure',
        },
        {
          value: '2',
          label: 'Maternité / congé pathologique',
        },
        {
          value: '3',
          label: 'Maladie professionnelle',
        },
        {
          value: '4',
          label: '1ère catégorie invalidité',
        },
        {
          value: '5',
          label: 'Autre',
        },
      ];
      if (user?.employee_information?.civility !== EmployeeCivility.Female) {
        selections = selections.filter((selection) => selection.value !== '2');
      }
      break;
    }
    case '22': {
      title = "Quel est le type d'accident ?";
      selections = [
        {
          value: '1',
          label: 'Accident de travail',
        },
        {
          value: '2',
          label: 'Accident de vie privée',
        },
      ];
      break;
    }
    case '31': {
      title = 'Combien avez vous eu de grossesse ? *';
      selections = [
        {
          value: '1',
          label: "C'est ma 1ère ou 2ème grossesse",
        },
        {
          value: '2',
          label: "C'est ma 3ème grossesse ou plus",
        },
      ];
      break;
    }
    default: {
      title = '';
      selections = [];
    }
  }

  return (
    <>
      <h3 className="question-form mt-5 mb-5 text-center">{title}</h3>
      {options.errorPregnancy && (
        <KerijBox
          type={KerijBoxType.Error}
          message={
            "Vous n'avez pas déclaré le congé matérnité. Veuillez prendre le temps de retourner et de procéder à cette déclaration"
          }
        />
      )}
      <div className="text-center">
        <div>
          <label>
            <select
              className="form-select"
              name="absenceType2"
              value={options.absenceType2 ?? ''}
              onChange={(e) => {
                options.setErrorPregnancy(false);
                options.setAbsenceType2(e.target.value);
                const index = (e.nativeEvent.target as HTMLSelectElement).selectedIndex;
                options.setAbsenceType2Label((e.nativeEvent.target as HTMLSelectElement)[index]?.innerHTML);
              }}
              required
            >
              {selections.map((selection) => (
                <option key={selection.value} value={selection.value}>
                  {selection.label}
                </option>
              ))}
            </select>
          </label>
        </div>
        {options.absenceType2 === '3' && (
          <KerijBox
            type={KerijBoxType.Warning}
            message={
              'Note: Cette information est confidentielle. Elle ne sera pas communiquée à votre employeur. Elle pourra être communiquée à l’assureur prévoyance de l’entreprise si la responsabilité civile d’un tiers est engagée.'
            }
          />
        )}
        {concatAbsence === '31' ? (
          <p className="text-muted">
            <em>
              <small>(*)Vos autres enfants sont à charge et/ou nés viables</small>
            </em>
          </p>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export const DeclarationType2Page = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const document = location.state.document || {};
  const featureVerifyEmployeeAbsenceMat = useAppFeature().VerifyEmployeeAbsenceMat;
  const [absenceType2, setAbsenceType2] = useState<string>(getAbsenceType2InitValue(document));
  const [absenceType2Label, setAbsenceType2Label] = useState<string>(getAbsenceType2InitLabel(document));
  const [errorPregnancy, setErrorPregnancy] = useState<boolean>(false);
  const user = useAppSelector(AccountContext.getUser);
  const absenceType = document?.absenceType?.value;

  const { setCurrentStep, changeStep } = useContext(NavigationContext);

  useEffect(() => {
    setCurrentStep(NavigationSteps.DECLARATION_TYPE_2);
  }, []);

  const handleClick = () => {
    const goToNext = (absenceMat?: Absence) =>
      changeStep(NavigationSteps.DECLARATION_DATE, {
        state: {
          document: {
            ...document,
            absenceType2: {
              value: absenceType2,
              label: absenceType2Label,
            },
            ...(absenceMat == null ? {} : { absenceMat }),
          },
        },
      });

    setErrorPregnancy(false);
    if (user) {
      const concatAbsence = ((document?.absenceType?.value as string) + document?.absenceType1?.value) as string;
      if (concatAbsence === '11' && absenceType2 === '5') {
        verifyAbsenceMat(featureVerifyEmployeeAbsenceMat).then(({ isValid, isHaving, dataAbsence }) => {
          if (isHaving) {
            goToNext(dataAbsence);
          } else if (isValid) {
            goToNext();
          } else {
            setErrorPregnancy(true);
          }
        });
      } else {
        goToNext();
      }
    } else {
      navigate('/login', { state: { ...location.state } });
    }
  };

  useEffect(() => {
    if (absenceType === '2') {
      changeStep(NavigationSteps.DECLARATION_DATE, {
        state: {
          document: {
            ...document,
            absenceType1: { value: null, label: null },
            absenceType2: { value: null, label: null },
          },
        },
      });
    }
  }, []);

  return (
    <DefaultLayout>
      <div className="container-fluid">
        <StepProgressBar type={absenceType} />
        <form className="form-legacy" onSubmit={handleClick} style={{ padding: 0 }}>
          {getAbsenceType2Component(document, user, {
            absenceType2,
            setAbsenceType2,
            setAbsenceType2Label,
            errorPregnancy,
            setErrorPregnancy,
          })}

          <Spacer size="medium" />
          <ActionButton startIcon={null} type="submit" variant="contained" actionName="next" />
          <Spacer />
          <Button
            color="secondary"
            onClick={() => {
              if (document.absenceType.value === '1') {
                if (document.absenceType1.value === '1') {
                  changeStep(NavigationSteps.DECLARATION_TYPE_1, {
                    state: location.state,
                  });
                } else {
                  changeStep(NavigationSteps.DECLARATION_PROLONGATION, {
                    state: location.state,
                  });
                }
              } else {
                changeStep(NavigationSteps.DECLARATION_TYPE_1, {
                  state: location.state,
                });
              }
            }}
          >
            Précédent
          </Button>
        </form>
      </div>
    </DefaultLayout>
  );
};
