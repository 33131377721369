import * as React from 'react';
import { Parcours } from '@/types';
import { ParcoursWhenInput } from '@/components/ParcoursInput';
import { PageFormBody, PageFormHelperText } from '@/components/Page';
import { ParcoursFrequencyInput } from '../ParcoursInput/ParcoursFrequencyInput';

export interface ParcoursWhenStepProps {
  ParcoursWhenInputProps: {
    value: Parcours['script']['when'];
    onChange: (value: Parcours['script']['when']) => void;
  };

  ParcoursFrequencyInputProps: {
    value: Parcours['script']['frequency'];
    onChange: (value: Parcours['script']['frequency']) => void;
  };
}

export const ParcoursWhenStep = (props: ParcoursWhenStepProps) => {
  const { ParcoursWhenInputProps, ParcoursFrequencyInputProps } = props;

  return (
    <PageFormBody
      before={
        <PageFormHelperText whiteSpace={'pre-wrap'}>
          {`Vous avez défini l’ensemble des conditions d’envoi du mail, il vous reste à définir le moment opportun de son envoi.

Lorsque vous souhaitez adresser le même mail, plusieurs fois, vous pouvez reprendre l’ensemble des éléments et décaler uniquement la date d’envoi.`}
        </PageFormHelperText>
      }
    >
      <ParcoursWhenInput
        value={ParcoursWhenInputProps.value}
        onChange={(value) => {
          ParcoursWhenInputProps.onChange?.(value);
        }}
      />
      <ParcoursFrequencyInput
        value={ParcoursFrequencyInputProps.value}
        onChange={(value) => {
          ParcoursFrequencyInputProps.onChange?.(value);
        }}
      />
    </PageFormBody>
  );
};
