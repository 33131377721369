import { ButtonProps } from '@mui/material';

export const ButtonPrimaryStyle: ButtonProps['sx'] = (theme) => {
  return {
    boxShadow: theme.shadows[3],
    '&:hover': {
      backgroundColor: theme.palette.aquaSerenity[300],
    },
    '&:active': {
      backgroundColor: theme.palette.aquaSerenity[400],
      boxShadow: theme.shadows[2],
    },
  };
};
