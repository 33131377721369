import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../DashboardRH.css';
import './Admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import { customStyles } from '@/shared/models/custom_modal.models';
import { useError } from '@/shared/useError';
import isAuthorized from '@/hooks/isAuthorized';
import { PRO_URL } from '@/constants/urls.constants';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import EditUserModal from './User/Edit';
import PermissionEditorModal from './User/ListBusiness';
import LockResetIcon from '@mui/icons-material/LockReset';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { rhAdminFetchUser } from '@/services/rh/admin';
import { fetchRhListCompanyBusiness } from '@/services/rh';
import ChangePasswordModal from '../Modal/ChangePassword.modal';
import DeleteUserModal from '../Modal/DeleteUser.modal';
import { StatusCodes } from 'http-status-codes';
import IconButton from '@mui/material/IconButton';
import { RHAccountContext, useAppSelector } from '@/store';
import { BusinessEntry, CompanyEntry } from '@/store/slice/rhNavbarContext';
import { PermissionBox } from '@/components/PermissionBox';
import { RHPage } from '@/components/RHPage';
import { ActionButton } from '@/components/ActionButton';
import { SearchField } from '../shared/components/Form';
import { Box } from '@mui/material';

export function AdminUtilisateurs() {
  const rh = useAppSelector(RHAccountContext.getProfile);

  const listSelectBusiness = useAppSelector((state) => state.rhNavbarContext.listSelectBusiness);
  const selectedIdBusiness = useAppSelector((state) => state.rhNavbarContext.selectedIdBusiness);
  const listSelectCompany = useAppSelector((state) => state.rhNavbarContext.listSelectCompany);

  const [modalIsOpenCreateUser, setModalIsOpenCreateUser] = useState(false);
  const [modalIsOpenGeneratePwd, setModalIsOpenGeneratePwd] = useState(false);
  const [modalIsOpenEdit, setModalIsOpenEdit] = useState(false);
  const [modalIsOpenDelete, setModalIsOpenDelete] = useState(false);
  const [modalIsOpenListBusiness, setModalIsOpenListBusiness] = useState(false);
  const [modalIsOpenListBusinessEdit, setModalIsOpenListBusinessEdit] = useState(false);
  const [search, setSearch] = useState('');
  const [listUsersAdmin, setListUsersAdmin] = useState<any[]>([]);
  const [select, setSelect] = useState();
  const { error, triggerError, clearError } = useError();

  // const [isShowAddRole, setIsShowAddRole] = useState(false)
  const navigate = useNavigate();
  const [listBusiness, setListBusiness] = useState<any[]>([]);

  const location = useLocation();
  const pathname = location.pathname;

  const getUsersAdmin = (listSelectBusiness: BusinessEntry[], listSelectCompany: CompanyEntry[]) => {
    if (rh) {
      const idBusinesses = rh?.rh_information?.id_business
        ? JSON.stringify([rh?.rh_information?.id_business])
        : listSelectBusiness.filter((value) => value.isChecked).map((value) => value.id).length > 0
        ? JSON.stringify(listSelectBusiness.filter((value) => value.isChecked).map((value) => value.id))
        : '[]';
      const idCompanies = rh?.rh_information?.id_parent_company
        ? JSON.stringify([rh?.rh_information?.id_parent_company])
        : listSelectCompany.some((value) => value.isChecked)
        ? JSON.stringify(listSelectCompany.filter((value) => value.isChecked).map((value) => value.id))
        : '[]';
      const idGroup = rh.rh_information.id_group;
      if (
        rh?.rh_information?.id_parent_company ||
        rh?.rh_information?.id_business ||
        (JSON.parse(idBusinesses).length > 0 && JSON.parse(idCompanies).length > 0)
      ) {
        rhAdminFetchUser({
          idBusiness: listSelectBusiness.some((value) => value.isChecked)
            ? // @ts-ignore TODO: Fix this !
              JSON.stringify(listSelectBusiness.filter((value) => value.isChecked).at(0).id)
            : selectedIdBusiness,
          idBusinesses: idBusinesses,
          idCompanies: idCompanies,
          idGroup: idGroup,
        }).then((value) => {
          if (value.status === StatusCodes.OK) {
            setListUsersAdmin(value.data?.adminUsers || []);
            triggerError('');
          } else {
            triggerError('Une erreur est survenue');
          }
        });
      } else {
        setListUsersAdmin([]);
      }

      if (rh?.rh_information?.id_group && rh?.rh_information?.id_parent_companies) {
        fetchRhListCompanyBusiness({
          idParentCompanies: JSON.stringify(JSON.parse(rh?.rh_information?.id_parent_companies || '[]')),
        }).then((value) => {
          setListBusiness(
            value.data?.companies?.map((value: any) => {
              value.isChecked = false;
              value.isShowed = false;
              value.businesses = value?.businesses?.map((value_: any) => {
                value_.isChecked = false;
                return value_;
              });
              return value;
            }),
          );
        });
      }
      if (rh?.rh_information?.id_parent_company) {
        fetchRhListCompanyBusiness({
          idParentCompany: rh?.rh_information?.id_parent_company,
          idBusinesses: rh?.rh_information?.id_businesses,
        }).then((value) => {
          setListBusiness(
            value.data?.companies.map((value: any) => {
              value.isChecked = false;
              value.isShowed = false;
              value.businesses = value?.businesses?.map((value_: any) => {
                value_.isChecked = false;
                return value_;
              });
              return value;
            }),
          );
        });
      }
    }
  };

  useEffect(() => {
    getUsersAdmin(listSelectBusiness, listSelectCompany);
  }, [rh, listSelectBusiness, listSelectCompany]);

  useEffect(() => {
    if (!isAuthorized(pathname)) {
      navigate(PRO_URL.PREFIXE_PRO + PRO_URL.LOGIN);
    }
  }, [localStorage.getItem('access_token_rh')]);

  return (
    <>
      <PermissionBox scope="admin" action="access">
        <RHPage
          title="Gérer les utilisateurs de KERij"
          actions={
            <ActionButton
              variant={'contained'}
              color="primary"
              actionName={'add'}
              onClick={() => setModalIsOpenCreateUser(true)}
            />
          }
          topElement={
            <Box ml={2.5} mr={2.5}>
              <SearchField placeholder="Rechercher" onChange={setSearch} />
            </Box>
          }
        >
          {listUsersAdmin.length > 0 &&
            listUsersAdmin
              .filter((value) => !search || value.firstname?.toLowerCase().includes(search.toLocaleLowerCase()))
              .map((value, index) => {
                return (
                  <div className="col-12 row align-items-center mb-3" key={index}>
                    <div className="col-4 prenom-nom">{value.firstname + ' ' + value.lastname}</div>
                    <div className="col-4 poste text-start">{value?.rh_login?.email}</div>
                    <div className="col-2 text-end">
                      <Tooltip title="Modifier le mot de passe">
                        <IconButton
                          onClick={() => {
                            setSelect(value.id);
                            setModalIsOpenGeneratePwd(true);
                          }}
                        >
                          <LockResetIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className="col-1 text-center">
                      <Tooltip title="Modifier l'utilisateur">
                        <IconButton
                          onClick={() => {
                            setSelect(value.id);
                            setModalIsOpenEdit(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className="col-1 text-center">
                      <Tooltip title="Supprimer l'utilisateur">
                        <IconButton
                          onClick={() => {
                            setSelect(value.id);
                            setModalIsOpenDelete(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                );
              })}
        </RHPage>
      </PermissionBox>

      <div>
        <EditUserModal
          isOpen={modalIsOpenCreateUser}
          onClosed={() => {
            setModalIsOpenCreateUser(false);
            getUsersAdmin(listSelectBusiness, listSelectCompany);
          }}
          user={undefined}
        />

        {listUsersAdmin.length > 0 && select !== undefined && modalIsOpenGeneratePwd && (
          <ChangePasswordModal
            visible={modalIsOpenGeneratePwd}
            handleCloseModal={() => {
              setModalIsOpenGeneratePwd(false);
            }}
            rhInformation={listUsersAdmin.find((value) => value.id === select)}
          />
        )}

        {listUsersAdmin.length > 0 && select !== undefined && modalIsOpenDelete && (
          <DeleteUserModal
            visible={modalIsOpenDelete}
            handleCloseModal={() => {
              setModalIsOpenDelete(false);
              setSelect(undefined);
              getUsersAdmin(listSelectBusiness, listSelectCompany);
            }}
            rhInformation={listUsersAdmin.find((value) => value.id === select)}
          />
        )}

        {listUsersAdmin.length > 0 && select !== undefined && modalIsOpenEdit && (
          <EditUserModal
            user={listUsersAdmin.find((value) => value.id === select)}
            isOpen={modalIsOpenEdit}
            onClosed={() => {
              setModalIsOpenEdit(false);
              getUsersAdmin(listSelectBusiness, listSelectCompany);
            }}
          />
        )}

        <PermissionEditorModal isOpen={modalIsOpenListBusiness} onClosed={() => setModalIsOpenListBusiness(false)} />

        {listUsersAdmin.length > 0 && select !== undefined && modalIsOpenEdit && modalIsOpenListBusinessEdit && (
          <Modal
            // @ts-ignore TODO: Fix this !
            style={customStyles}
            className="Modal modal-style-child"
            overlayclassName="Overlay"
            closeTimeoutMS={200}
            isOpen={modalIsOpenListBusinessEdit}
            onRequestClose={() => {
              setModalIsOpenListBusinessEdit(!modalIsOpenListBusinessEdit);
            }}
            ariaHideApp={false}
          >
            <div className="container-fluid col-12">
              <div className="col-12 d-flex flex-row-reverse">
                <span
                  className="close text-right"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setModalIsOpenListBusinessEdit(false)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </div>
              <div className="text-center">
                <span className="modal-titre">ADMINISTRATION</span>
              </div>
              <br />
              <div className="text-center mb-2">
                <span className="modal-sous-titre">Liste des établissements</span>
              </div>
              <div className="col-12 text-start">
                <div className="text-start mb-3">
                  {listBusiness
                    .filter(
                      (value) =>
                        listSelectCompany.find((value_) => value_.isChecked) &&
                        // @ts-ignore TODO: Fix this !
                        value.id === listSelectCompany.find((value_) => value_.isChecked).id,
                    )
                    .map((value, index) => {
                      return (
                        <div className="align-items-center" key={value.id}>
                          <div
                            onClick={() => {
                              setListBusiness((listBusiness) =>
                                listBusiness.map((value_) => {
                                  if (value_.id === value.id) {
                                    value_.isShowed = !value_.isShowed;
                                  }
                                  return value_;
                                }),
                              );
                            }}
                          >
                            {value.isShowed ? (
                              <FontAwesomeIcon
                                // @ts-ignore TODO: Fix this !
                                icon="fa-solid fa-caret-down"
                                className="mx-2 align-caret-checkbox"
                                style={{
                                  cursor: 'pointer',
                                }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                // @ts-ignore TODO: Fix this !
                                icon="fa-solid fa-caret-right"
                                className="mx-2 align-caret-checkbox"
                                style={{
                                  cursor: 'pointer',
                                }}
                              />
                            )}
                            <input
                              className="form-check-input checkbox-business my-2 align-items-center"
                              type="checkbox"
                              value=""
                              id={value.id}
                              onChange={() =>
                                setListBusiness((listBusiness) =>
                                  listBusiness.map((value_) => {
                                    if (value_.id === value.id) {
                                      value_.isChecked = !value_.isChecked;
                                      value_.businesses = value_.businesses.map((v: any) => {
                                        v.isChecked = value_.isChecked;
                                        return v;
                                      });
                                      value_.isShowed = true;
                                    }
                                    return value_;
                                  }),
                                )
                              }
                              checked={value.isChecked}
                            ></input>
                            <label
                              className="form-check-label align-items-center align-name-checkbox"
                              htmlFor={value.id}
                            >
                              {value.name}
                            </label>
                          </div>
                          {value.isShowed &&
                            value.businesses.length > 0 &&
                            value.businesses.map((value_: any) => {
                              return (
                                <div className="ml-5 mx-5 d-flex form-check text-start" key={value_.id}>
                                  <input
                                    className="form-check-input checkbox-business my-2"
                                    type="checkbox"
                                    value=""
                                    id={value_.id}
                                    onChange={() =>
                                      setListBusiness((listBusiness) =>
                                        listBusiness.map((v) => {
                                          if (v.id === value.id) {
                                            v.businesses = v.businesses.map((va: any) => {
                                              if (va.id === value_.id) {
                                                va.isChecked = !va.isChecked;
                                              }
                                              return va;
                                            });
                                            v.isChecked =
                                              v.businesses.filter((v: any) => v.isChecked).length === 0 ? false : true;
                                            v.isShowed = true;
                                          }
                                          return v;
                                        }),
                                      )
                                    }
                                    checked={value_.isChecked}
                                  ></input>
                                  <label className="form-check-label align-name-checkbox" htmlFor={value_.id}>
                                    {value_.name}
                                  </label>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="col-12">
                <div className="text-center">
                  <button
                    className="btn-kerij"
                    onClick={() => setModalIsOpenListBusinessEdit(!modalIsOpenListBusinessEdit)}
                  >
                    Valider
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}
