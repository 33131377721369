import { Box } from '@mui/material';
import React from 'react';
import { ActionButton } from '@/components/ActionButton';

interface CloseAbsenceModalFooterProperties {
  onCancel: () => void;
  onConfirm: () => void;
  cancelLabel?: string;
  disableConfirm?: boolean;
}

export const CloseAbsenceModalFooter = ({
  onCancel,
  onConfirm,
  disableConfirm,
  cancelLabel,
}: CloseAbsenceModalFooterProperties) => {
  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'right',
      }}
    >
      <ActionButton actionName="cancel" label={cancelLabel || 'Annuler'} onClick={onCancel} sx={{ mr: 1 }} />
      <ActionButton
        disabled={disableConfirm}
        actionName="validate"
        label={'Confirmer'}
        onClick={onConfirm}
        sx={{ mr: 1 }}
      />
    </Box>
  );
};
