import { ButtonProps } from '@mui/material';

export const ButtonErrorStyle: ButtonProps['sx'] = (theme) => {
  return {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.colorScheme.outlineVariant,
    boxShadow: theme.shadows[1],
    '&:hover': {
      backgroundColor: theme.colorScheme.error,
      borderColor: theme.colorScheme.outlineVariant,
      color: theme.colorScheme.onError,
    },
    '&:active': {
      backgroundColor: theme.palette.common.white,
      color: theme.colorScheme.error,
      borderColor: theme.colorScheme.outlineVariant,
      boxShadow: theme.shadows[0],
    },
  };
};
