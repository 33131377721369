import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './AccueilRH.css';
import { useLocation, useNavigate } from 'react-router-dom';
import imgAbsences from '@/assets/img-absences-dashboard.svg';
import { PRO_URL } from '@/constants/urls.constants';
import {
  Card,
  CardActions,
  CardHeader,
  CircularProgress,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { getFullName } from '@/shared/utils/getFullName.service';
import { AbsencesFilters, fetchRhAbsences, fetchRhAbsencesCount } from '@/services/rh';
import { IfPermission } from '@/components/IfPermission';
import { Absence, AbsenceStatus } from '@/types';
import { ListCardContent } from '../ListCard';
import { ActionButton } from '@/components/ActionButton';

const ProgressAbsence = () => {
  const [data, setData] = React.useState<Absence[]>([]);
  const navigate = useNavigate();
  const [length, setLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const loadData = async () => {
    const count = await fetchRhAbsencesCount();
    setLength(count.data);
    const filters: AbsencesFilters = {
      status: [AbsenceStatus.DRAFT, AbsenceStatus.PENDING, AbsenceStatus.VALIDATED],
      detail: true,
      page: 1,
      per_page: 4,
    };
    const absences = await fetchRhAbsences(filters);
    setData(absences.data);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const noAbsences = () => {
    return (
      <div className="mt-5">
        <div className="col-12 text-center">
          <strong>{`Il n'y a aucune absence en cours`}</strong>
        </div>
      </div>
    );
  };

  const previewAbsences = () => {
    return data.slice(0, 4).map((value) => (
      <TableRow
        key={value.id}
        style={{ cursor: 'pointer ' }}
        onClick={() => {
          navigate(PRO_URL.PREFIXE_PRO + PRO_URL.PROGRESS_ABSENCE, {
            state: {
              document: {
                idSalarie: value.employee_information?.id,
                idBusiness: value.employee_information?.id_business,
              },
              previousPath: location.pathname,
            },
          });
        }}
      >
        <TableCell>{value.employee_information ? getFullName(value.employee_information) : ''}</TableCell>
        <TableCell>
          <span>{value.type_absence.description_label}</span>
        </TableCell>
        <TableCell>
          <span>Depuis le {moment(value.date_start).format('DD/MM/YYYY')}</span>
        </TableCell>
      </TableRow>
    ));
  };

  const buttonAllAbsences = () => {
    return (
      <IfPermission scope="absence-management" action="edit">
        <ActionButton
          color="secondary"
          actionName="next"
          label={'Tous les dossiers ouverts'}
          onClick={() => {
            navigate(PRO_URL.PREFIXE_PRO + PRO_URL.PROGRESS_ABSENCE);
          }}
        />
      </IfPermission>
    );
  };
  return (
    <Card className="col-12" sx={{ minHeight: 350 }}>
      <CardHeader
        avatar={<img src={imgAbsences} />}
        className={'header-absences'}
        title={<>Dossiers ouverts {length > 0 && <span className="pastille-nb-actions text-end">{length}</span>}</>}
        // subheader="September 14, 2016"
      />
      <ListCardContent>
        {loading && (
          <div className="col-12 text-center">
            <CircularProgress />
          </div>
        )}

        {!loading && (
          <TableContainer>
            <Table className="list-group-item">{data.length > 0 ? previewAbsences() : noAbsences()}</Table>
          </TableContainer>
        )}
      </ListCardContent>
      <CardActions disableSpacing>{length > 4 && buttonAllAbsences()}</CardActions>
    </Card>
  );
};

export default ProgressAbsence;
