import React from 'react';
import { Box, TableCell, TableRow } from '@mui/material';
import { Button } from '@/components/Button';
import { UploadFileRounded } from '@mui/icons-material';

interface DocumentUploaderFooterProperties {
  disabled?: boolean;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DocumentUploaderFooter = ({ disabled, onFileChange }: DocumentUploaderFooterProperties) => {
  return (
    <TableRow>
      <TableCell colSpan={5}>
        <div className={'row'}>
          <Box className="col-6 offset-6" textAlign="right">
            <Button startIcon={<UploadFileRounded sx={{ height: 18 }} />} color={'secondary'} disabled={disabled}>
              <input
                className="form-control form-file input-file"
                name="document"
                type="file"
                id="file"
                value=""
                hidden
                onChange={onFileChange}
                accept="image/jpeg, image/png, application/pdf"
              />
              {'Sélectionner un fichier'}
            </Button>
          </Box>
          <Box className="col-6 offset-6 col text-muted small" textAlign="right">
            <em>{'Taille des fichiers : 5 Mo maximum'}</em>
          </Box>
        </div>
      </TableCell>
    </TableRow>
  );
};
