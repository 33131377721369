import * as React from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PRO_URL } from '@/constants/urls.constants';
import { RHPage } from '@/components/RHPage';
import { ActionButton } from '@/components/ActionButton';
import { Spacer } from '@/components/Spacer';
import { Home } from '@mui/icons-material';

function getBackURL() {
  return PRO_URL.PRO;
}

// Name and options
const componentName = 'ComingSoonPage';

// Component properties
export interface ComingSoonPageProps extends React.ComponentProps<'div'> {}

export function ComingSoonPage(props: ComingSoonPageProps) {
  const navigate = useNavigate();

  return (
    <RHPage title="En cours de construction">
      <Typography variant="h5">
        Les équipes KERij sont en train de construire cette fonctionnalité, n'hésitez pas à revenir plus tard !
      </Typography>
      <Spacer />
      <ActionButton
        actionName="previous"
        variant={'contained'}
        color="primary"
        label={`Retour à l'accueil`}
        startIcon={<Home />}
        onClick={() => navigate(getBackURL())}
      />
    </RHPage>
  );
}
