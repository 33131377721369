import React, { useState } from 'react';
import { useError } from '@/shared/useError';
import { KerijBox, KerijBoxType } from '../../../../components/base';
import EmployeeCard from './EmployeeCard';
import TitleProcess from './TitleProcess';
import AppSelectSearch from '../../../../components/AppSelectSearch';
import useDebounce from '../../../../hooks/useDebounce';
import { useSearchEmployee } from '../api';
import { Box } from '@mui/material';
import { getFullName } from '../../../../shared/utils/getFullName.service';
import { EmployeeInformation as EmployeeInformationInterface } from '../../../../types/user.interface';
import { useAppSelector } from '@/store';

interface EmployeeInformationProperties {
  value: EmployeeInformationInterface | null;
  onChange: (employee: EmployeeInformationInterface | null) => void;
}

export const EmployeeInformation = ({ value, onChange }: EmployeeInformationProperties) => {
  const selectedIdBusiness = useAppSelector((state) => state.rhNavbarContext?.listSelectBusiness);

  const [employeeMatricule, setEmployeeMatricule] = useState<string>('');
  const { error, triggerError, clearError } = useError();
  const search = useDebounce(employeeMatricule, 500);

  React.useEffect(() => {
    if (selectedIdBusiness) {
      clearError();
    } else {
      triggerError("N'oubliez pas de choisir une société dans la barre de navigation");
    }
  }, [selectedIdBusiness]);

  const { data } = useSearchEmployee({
    params: {
      firstname: search,
      lastname: search,
      description: search,
    },
  });

  const EmployeeList = data?.data?.rows || [];

  const onSelected = (item: any) => {
    if (item) {
      const employee = EmployeeList.find((e: EmployeeInformationInterface) => e?.id === item?.id);
      onChange(employee);
    } else {
      onChange(null);
    }
  };

  return (
    <>
      <div className="form-declaration col-12">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            className="col-8"
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              marginTop: '20px',
            }}
          >
            <AppSelectSearch
              fullWidth
              name="keyword"
              placeholder={'Prénom ou Nom ou Matricule'}
              options={EmployeeList?.map((item: EmployeeInformationInterface) => {
                return {
                  id: item.id,
                  label: `${item.firstname}`,
                  labelShow: `${getFullName(item)} (matricule: ${item.description})`,
                };
              })}
              rules={undefined}
              error={false}
              helperText={undefined}
              onChangeInput={(newValue: any) => {
                setEmployeeMatricule(newValue);
              }}
              onSelected={(newValue: any) => {
                onSelected(newValue);
              }}
            />
          </Box>
        </div>

        {error ? <KerijBox type={KerijBoxType.Error} message={error} /> : <></>}

        {value ? (
          <div className="col-8 offset-2 typeMatricule-cadre mt-5">
            <TitleProcess title="Informations du salarié" />
            <EmployeeCard idEmployee={value?.id} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
