import { Box, CircularProgress, Divider, Grid, Input, InputLabel, Typography } from '@mui/material';
import { StatusCodes } from 'http-status-codes';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import logo from '../../../Logotype-Icone-Fondclair.svg';
import CustomModal from '@/components/base/CustomModal';
import KerijButton from '@/components/base/button/kerijButton';
import { fetchRhProfile, fetchRhRoleById, updateRhProfile } from '@/services/rh';
import { RHInformation, RHLoginInterface, RHRole } from '@/types';

import ChangePasswordModal from '@/components/DashboardRH/ChangePasswordModal/ChangePasswordModal';
import { Create } from '@/components/DashboardRH/Administration/AdminRoles/Create';
import { ActionButton } from '@/components/ActionButton';
import { RHPage } from '@/components/RHPage';
import { Spacer } from '@/components/Spacer';

interface IEditModal {
  isOpen: boolean;
  onClose: () => void;
  rhProfile: RHInformation;
}

const EditModal = ({ isOpen, rhProfile, onClose }: IEditModal) => {
  const [profile, setProfile] = useState(rhProfile);
  const handleSubmit = () => {
    updateRhProfile(profile).then((response) => response.status === StatusCodes.OK && onClose());
  };

  return (
    <CustomModal
      open={isOpen}
      setOpen={() => onClose()}
      showExitIcon={true}
      renderHeader={() => <>Modifier mes informations de profil</>}
      renderFooter={() => <KerijButton label="Valider" onClick={() => handleSubmit()} />}
      renderContent={() => (
        <Form>
          <InputLabel>Nom</InputLabel>
          <Input
            name={'lastname'}
            value={profile.lastname}
            onChange={(event) =>
              setProfile((profile) => ({
                ...profile,
                lastname: event.target.value,
              }))
            }
          />
          <InputLabel>Prénom</InputLabel>
          <Input
            name={'firstname'}
            value={profile.firstname}
            onChange={(event) =>
              setProfile((profile) => ({
                ...profile,
                firstname: event.target.value,
              }))
            }
          />
        </Form>
      )}
    ></CustomModal>
  );
};

export const EditProfilPage: React.FC = () => {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenRoleModal, setIsOpenRoleModal] = useState(false);

  const [myProfile, setMyProfile] = useState<RHLoginInterface | null>(null);
  const [changePasswordVisible, setChangePasswordVisible] = useState<boolean>(false);
  const [role, setRole] = useState<RHRole | undefined>(undefined);

  useEffect(() => {
    if (!myProfile) return;

    fetchRhRoleById(myProfile.rh_information.rh_secures?.[0].id_role)
      .then((response) => {
        setRole(response.data);
      })
      .catch((error) => {
        console.error("Une erreur s'est produite :", error);
      });
  }, [myProfile]);

  const fetchMyProfile = async () => {
    const profileResponse = await fetchRhProfile();
    setMyProfile(profileResponse.data?.rh);
  };

  useEffect(() => {
    fetchMyProfile();
  }, []);

  if (!myProfile)
    return (
      <Box display={'flex'} height={'100vh'}>
        <CircularProgress sx={{ margin: 'auto' }} />
      </Box>
    );

  const {
    email,
    rh_information: {
      firstname,
      lastname,
      business: { name: businessName },
    },
    rh_information,
  } = myProfile;
  return (
    <RHPage title="Mon Profil">
      <Grid container spacing={2}>
        <Grid item xs={1} lg={2}></Grid>
        <Grid item xs={10} lg={8}>
          <Box
            sx={(theme) => ({
              borderRadius: 1,
              boxShadow: theme.shadows[3],
              padding: theme.spacing(4),
              backgroundColor: theme.palette.common.white,
            })}
          >
            <Box display={'flex'}>
              <Typography variant="h5" flexGrow={1}>
                {firstname} {lastname?.toUpperCase()}
              </Typography>
              <Box>
                <img alt="kerij" src={logo} width="50" />
              </Box>
            </Box>

            <Spacer />

            <Box>
              <Typography fontWeight={700}>SOCIETE</Typography>
              <Divider />
              <Spacer size="small" />
              <Box>{businessName}</Box>
            </Box>

            <Spacer />

            <Box>
              <Typography fontWeight={700}>COMPTE</Typography>
              <Divider />
              <Spacer size="small" />
              <Box display={'flex'}>
                <Box flexGrow={1}>
                  <Typography variant="caption">Email</Typography>
                </Box>
                <Box>{email}</Box>
              </Box>
              <Spacer size="small" />
              <Box display={'flex'}>
                <Box flexGrow={1} pt={1}>
                  <Typography variant="caption">Mot de passe</Typography>
                </Box>
                <Box>
                  <ActionButton label={'Modifier Password'} onClick={() => setChangePasswordVisible(true)} />
                </Box>
              </Box>
            </Box>

            <Spacer />

            <Box>
              <Typography fontWeight={700}>ROLE</Typography>
              <Divider />
              <Spacer size="small" />
              <Box display={'flex'}>
                <Box flexGrow={1} pt={1}>
                  <Typography variant="caption" textTransform={'capitalize'}>
                    {role?.role_name}
                  </Typography>
                </Box>
                <Box>
                  <ActionButton label={'Autorisations'} onClick={() => setIsOpenRoleModal(true)} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <EditModal
        isOpen={isOpenEditModal}
        rhProfile={rh_information}
        onClose={async () => {
          await fetchMyProfile();
          setIsOpenEditModal(false);
        }}
      />
      <ChangePasswordModal isVisible={changePasswordVisible} onClose={() => setChangePasswordVisible(false)} />
      <CustomModal
        open={isOpenRoleModal}
        onClose={() => {
          setIsOpenRoleModal(false);
        }}
        renderHeader={() => {
          return <></>;
        }}
        renderContent={() => (
          <div className="container-fluid col-12">
            <Create
              onRoleCreated={(role: RHRole) => {
                setIsOpenRoleModal(false);
              }}
              role={role}
              readonly={true}
            />
          </div>
        )}
      />
    </RHPage>
  );
};
