/* eslint-disable security/detect-object-injection */
import * as React from 'react';
import { ParcoursScriptPresence } from '@/types';

/**
 *
 * @param value
 */
export function parcoursPresence(value: ParcoursScriptPresence): React.ReactNode {
  return {
    [ParcoursScriptPresence.Enum.any]: (
      <>
        Le salarié peut être <strong>présent</strong> ou <strong>absent</strong> lors de l&lsquo;envoi du mail
      </>
    ),
    [ParcoursScriptPresence.Enum.absent]: (
      <>
        Le salarié doit être <strong>présent</strong> lors de l&lsquo;envoi du mail
      </>
    ),
    [ParcoursScriptPresence.Enum.present]: (
      <>
        Le salarié doit être <strong>absent</strong> lors de l&lsquo;envoi du mail
      </>
    ),
  }[value];
}
