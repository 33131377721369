import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IfPermission, IfPermissionProps } from '../../IfPermission';
import { Feature } from '@/types';
import { useAppFeature } from '@/store';

export interface LayoutMenuItemProps {
  text: string;
  permission?: IfPermissionProps;
  feature?: keyof Feature;
  url?: string;
  external?: boolean;
  subItem?: boolean;
  onClick?: () => void;
}

export function LayoutMenuItem(props: LayoutMenuItemProps) {
  const { permission, feature } = props;
  const appFeature = useAppFeature();

  return feature && !appFeature[feature] ? null : (
    <>
      {permission == null ? (
        <ItemContent {...props} />
      ) : (
        <IfPermission {...permission}>
          <ItemContent {...props} />
        </IfPermission>
      )}
    </>
  );
}

function ItemContent(props: LayoutMenuItemProps) {
  const location = window.location.pathname;
  const navigate = useNavigate();
  const { text, url, subItem, external, onClick } = props;
  const isSelected = React.useMemo(() => location === url, [location, url]);
  const linkProps = external
    ? {
        component: 'a',
        href: url,
        target: '_blank',
      }
    : {
        onClick: () => {
          if (url != null) {
            navigate(url);
          }
          onClick?.();
        },
      };
  return (
    <ListItem
      sx={(theme) => ({
        padding: 0,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      })}
    >
      <ListItemButton
        selected={isSelected}
        {...linkProps}
        style={{ borderRadius: 6 }}
        sx={(theme) => ({
          height: theme.spacing(3.5),
          padding: 0,
        })}
      >
        <Typography
          fontSize={12}
          fontWeight={600}
          sx={(theme) => ({
            padding: theme.spacing(0.75),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
          })}
        >
          {text}
        </Typography>
      </ListItemButton>
    </ListItem>
  );
}
